import { React } from "react";

export default function ImageIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <path id="画像アイコン" d="M26.5,24.056V6.944A2.452,2.452,0,0,0,24.056,4.5H6.944A2.452,2.452,0,0,0,4.5,6.944V24.056A2.452,2.452,0,0,0,6.944,26.5H24.056A2.452,2.452,0,0,0,26.5,24.056ZM11.222,17.333l3.056,3.679L18.556,15.5l5.5,7.333H6.944Z" transform="translate(-4.5 -4.5)" fill="#2f3d4a"/>
    </svg>
 );
}
