import { React, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useMyData } from './MyProvider';


export default function ConfirmDialog(props) {
  const { signOut } = useMyData();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleYes = () => {
    setOpen(false);
    props.onYes();
  };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {props.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={"my"} autoFocus onClick={handleClose}>いいえ</Button>
          <Button variant="contained" className={"my"} onClick={handleYes}>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </>
 );

}
