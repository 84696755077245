import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import { Badge } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Dekigoto.scss';
import FilesDialog from "./FilesDialog";
import FileDataView from "./FileDataView";

export default function DekigotoListTileTile({data }) {
  const { loadDekigotoFiles } = useMyData();

  const navigate = useNavigate();

  const [files, setFiles] = useState([]); // Files
  const [mainfile, setMainFile] = useState([]); // MainFiles
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  const jumpPage = (e) => {
    navigate('/dekigoto/detail/' + e.currentTarget.dataset.id);
  }

  let ffiles = [];
  useEffect(() => {
    loadDekigotoFiles(data.v4, setInitFiles);
  }, []);

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }

  useEffect(() => {
    let mainFile = [];
    files.forEach(ele => {
      if(ele.mainimg==1) {
        mainFile.push(ele);
      }
    });
    setMainFile(mainFile);
  },[files]);

  const printPage = (e) => {
    var myWindow=window.open('/dekigoto/detail/' + e.currentTarget.dataset.id + '?print=1','_blank');
  }

  const openFilesDialog = (e) => {
    setFilesDialogOpen(true);
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }


  return (
      <>
        {data && 
        <>
          <div className="tile">
            <div className="imageArea">
              {mainfile.map((data,i) => <FileDataView key={data.id} data={data} mode="tile" show={i==0} /> )}
            </div>

            <div className="dataArea">
              <div className="dataAreaMain">
                <div>
                  <div className="date">{data.date}</div>
                  <div onClick={jumpPage} data-id={data.v4} className="title">{data.title}</div>
                </div>
                <div>
                  <div className="company">関連会社：{data.company.join('、')}</div>
                  <div className="mdate">更新日　：{data.mdate}</div>
                  <div className="user">更新者：{data.user_name}</div>
                </div>
              </div>
              <div className="dataAreaSub">
                <div>
                  <div className="media">{data.media.map((item,i0)=>{return <Fragment key={i0}>{item}<br /></Fragment>})}</div>
                </div>
                <div>
                  {files.length > 0 && 
                  <Badge badgeContent={files.length} color="accent">
                    <button onClick={openFilesDialog}>付属データ</button>
                  </Badge>
                  }
                  <Badge color="accent">
                    <button onClick={printPage} data-id={data.v4}>印刷</button>
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          <FilesDialog open={filesDialogOpen} onClose={closeFilesDialog} files={files}  />
        </>
        }
      </>

  );
}
