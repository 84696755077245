import { React } from "react";

export default function BreadCrumbsIcon() {

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="9.262" height="15" viewBox="0 0 9.262 15">
  <path id="Icon_material-navigate-next" data-name="Icon material-navigate-next" d="M12.5,7.5,10.738,9.262,16.462,15l-5.725,5.737L12.5,22.5,20,15Z" transform="translate(-10.737 -7.5)" fill="#2f3d4a"/>
</svg>
 );
}
