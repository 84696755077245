import { React } from "react";

export default function KeepPinIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.385" height="18.385" viewBox="0 0 18.385 18.385">
      <g id="グループ_10870" data-name="グループ 10870" transform="translate(-4482.35 -9321.789) rotate(45)">
        <path id="パス_10083" data-name="パス 10083" d="M0,0H6L7,12H-1Z" transform="translate(9771 3415)" fill="#2f3d4a"/>
        <rect id="長方形_443" data-name="長方形 443" width="10" height="3" rx="1.5" transform="translate(9769 3414)" fill="#2f3d4a"/>
        <rect id="長方形_444" data-name="長方形 444" width="12" height="3" rx="1.5" transform="translate(9768 3424)" fill="#2f3d4a"/>
        <rect id="長方形_445" data-name="長方形 445" width="2" height="10" rx="1" transform="translate(9773 3424)" fill="#2f3d4a"/>
      </g>
    </svg>
 );
}
