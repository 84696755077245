import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

import './Dekigoto.scss';
import DekigotoEditMain from "./DekigotoEditMain";
import DekigotoEditData from "./DekigotoEditData";
import DekigotoEditPreview from "./DekigotoEditPreview";

export default function DekigotoEdit() {
  let {id} = useParams();

  const { setMenu,getMedias,getCompanies,getDekigoto } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル

  const [media,setMedia] = useState([]); // 掲載場所
  const [company,setCompany] = useState([]); // 関連会社

  const [dekigoto, setDekigoto] = useState({}); // 出来事データ


  const [tab,setTab] = useState(0); // TAB

  useEffect(()=>{
    getMedias(setMedia,id);
  },[id]);

  useEffect(()=>{
    getCompanies(setCompany,id);
  },[id]);

  useEffect(()=>{
    getDekigoto(setDekigoto,id);
  },[id]);

  useEffect(()=>{
    if(typeof dekigoto.date !== 'undefined' ){
      const dates = dekigoto.date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(dekigoto.title);
    }
  
  },[dekigoto]);


  useEffect(()=>{
    setMenu('dekigoto');
  },[]);


  return (
    <div className="dekigoto">
      <div className="date">{yearProps.value}/{monthProps.value}/{dayProps.value}</div>
      <div className="title">{titleProps.value}</div>
      <div className="tabs">
        <div className={"tab" + (tab==0 ? ' active' : '')} onClick={()=>{setTab(0)}} data-tab="0">基本情報入力</div>
        <div className={"tab" + (tab==1 ? ' active' : '')} onClick={()=>{setTab(1)}} data-tab="1">資料データ入力</div>
        <div className={"tab preview" + (tab==2 ? ' active' : '')} onClick={()=>{setTab(2)}} data-tab="2">プレビュー</div>
      </div>
      {tab==0 && 
        <DekigotoEditMain id={id} dekigoto={dekigoto} company={company} media={media} />
      }
      {tab==1 && 
        <DekigotoEditData id={id} dekigoto={dekigoto} company={company} media={media} />
      }
      {tab==2 && 
        <DekigotoEditPreview id={id} dekigoto={dekigoto} company={company} media={media} />
      }
    </div>
 );
}
