import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Dekigoto.scss';

export default function DekigotoListList({list}) {

  const navigate = useNavigate();

  const jumpPage = (e) => {
    //navigate('/dekigoto/data/' + e.currentTarget.dataset.id);
    navigate('/dekigoto/detail/' + e.currentTarget.dataset.id);
  }


  return (

          <table>
            <thead>
              <tr>
                <th>タイトル</th>
                <th>年月日</th>
                <th>掲載場所</th>
                <th>更新日</th>
                <th>更新者</th>
              </tr>
            </thead>
            <tbody>
              {list.list.map((data, i) => <tr key={i} onClick={jumpPage} data-id={data.v4}>
                <td>{data.title}</td>
                <td>{data.date}</td>
                <td>{data.mediatext}</td>
                <td>{data.mdate}</td>
                <td>{data.user_name}</td>
              </tr>)}
            </tbody>
          </table>

  );
}
