import { React } from "react";

export default function VisibleIcon() {

  return (
  <svg id="eye" xmlns="http://www.w3.org/2000/svg" width="22.719" height="15.619" viewBox="0 0 22.719 15.619">
    <path id="パス_10032" data-name="パス 10032" d="M22.719,13.435s-4.26-7.81-11.359-7.81S0,13.435,0,13.435s4.26,7.81,11.359,7.81S22.719,13.435,22.719,13.435Zm-21.053,0a18.649,18.649,0,0,0,2.357,2.9,10.581,10.581,0,0,0,7.337,3.489A10.589,10.589,0,0,0,18.7,16.336a18.649,18.649,0,0,0,2.357-2.9,18.648,18.648,0,0,0-2.357-2.9,10.591,10.591,0,0,0-7.338-3.489,10.589,10.589,0,0,0-7.338,3.489,18.647,18.647,0,0,0-2.357,2.9Z" transform="translate(0 -5.625)" fill="#2e3d49" fillRule="evenodd"/>
    <path id="パス_10033" data-name="パス 10033" d="M15.095,11.545a3.55,3.55,0,1,0,3.55,3.55,3.55,3.55,0,0,0-3.55-3.55Zm-4.97,3.55a4.97,4.97,0,1,1,4.97,4.97A4.97,4.97,0,0,1,10.125,15.095Z" transform="translate(-3.735 -7.285)" fill="#2e3d49" fillRule="evenodd"/>
  </svg>
 );
}
