import { React } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useMyData } from './MyProvider';
import './Main.scss';
import Top from "./Top";
import DekigotoAdd from "./DekigotoAdd";
import DekigotoEdit from "./DekigotoEdit";
import DekigotoList from "./DekigotoList";
import DekigotoDetail from "./DekigotoDetail";
import PublicationAdd from "./PublicationAdd";
import PublicationEdit from "./PublicationEdit";
import PublicationList from "./PublicationList";
import CompanyAdd from "./CompanyAdd";
import CompanyEdit from "./CompanyEdit";
import CompanyDetail from "./CompanyDetail";
import CompanyList from "./CompanyList";
import CompanyExport from "./CompanyExport";


export default function Blank() {
  const { data, signOut } = useMyData();
  const navigate = useNavigate();

  return (
    <div className="main">
      <Routes>
        <Route path="/dekigoto/">
          <Route path="list" element={<DekigotoList />} />
          <Route path="list/:page" element={<DekigotoList />} />
          {data.role>=3 && <Route path="add" element={<DekigotoAdd />} />}
          {data.role>=3 && <Route path="data/:id" element={<DekigotoEdit />} />}
          <Route path="detail/:id" element={<DekigotoDetail />} />
          <Route path="*" element={<Blank />} />
        </Route>
        <Route path="/publication/">
          <Route path="list" element={<PublicationList />} />
          {data.role>=3 && <Route path="add" element={<PublicationAdd />} />}
          {data.role>=3 && <Route path="data/:id" element={<PublicationEdit />} />}
          <Route path="*" element={<Blank />} />
        </Route>
        <Route path="/company/">
          <Route path="list" element={<CompanyList />} />
          {data.role>=3 && <Route path="add" element={<CompanyAdd />} />}
          {data.role>=3 && <Route path="data/:id" element={<CompanyEdit />} />}
          <Route path="detail/:id" element={<CompanyDetail />} />
          {data.role>=3 && <Route path="export" element={<CompanyExport />} />}
          <Route path="*" element={<Blank />} />
        </Route>
        <Route path="*" element={<Top />} />
      </Routes>
    </div>
  );
}
