import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialogEditable";
import CompanySelectDialog from "./CompanySelectDialogBig";
import LoadingButton from '@mui/lab/LoadingButton';

import './Dekigoto.scss';

export default function DekigotoEditMain(props) {
  const navigate = useNavigate();
  //let {id} = useParams();
  const [ id, setId ] = useState(props.id);
  const [ dekigoto, setDekigoto ] = useState(props.dekigoto); // 出来事データ
  const [ media, setMedia ] = useState(props.media); // 掲載場所
  const [ company, setCompany ] = useState(props.company); // 関連会社

  const { data,setMenu,getMedias,getCompanies,dekigotoUpdate,getDekigoto } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル
  const [descriptionProps,, setDescription] = useInput(""); // 説明文章①
  const [commanderProps,, setCommander] = useInput(""); // 作業指示者
  const [nameProps,, setName] = useInput(data.name||""); // 氏名
  const [postProps,, setPost] = useInput(data.post||""); // 所属・部署名
  const [commentProps,, setComment] = useInput(""); // コメント


  const [loading,setLoading] = useState(false);

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [ret, setRet] = useState(true); // 登録時の戻り値


  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if(e){
      setMedia(e);
    }
  }

  const openCompanyDialog = (e) => {
    setCompanyDialogOpen(true);
  }
  const closeCompanyDialog = (e) => {
    setCompanyDialogOpen(false);
    if(e){
      setCompany(e);
    }
  }


  useEffect(()=>{
    if(typeof dekigoto.date !== 'undefined' ){
      const dates = dekigoto.date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(dekigoto.title);
      setDescription(dekigoto.description);
      setCommander(dekigoto.commander);
      setName(dekigoto.user_name);
      setPost(dekigoto.user_post);
      setComment(dekigoto.comment);
    }
  },[dekigoto]);


  useEffect(()=>{
    setMenu('dekigoto');
  },[]);

  useEffect(()=>{
    setId(props.id);
  },[props.id]);

  useEffect(()=>{
    setDekigoto(props.dekigoto);
  },[props.dekigoto]);

  useEffect(()=>{
    setMedia(props.media);
  },[props.media]);

  useEffect(()=>{
    setCompany(props.company);
  },[props.company]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(yearProps.value==="") err=true;
    if(yearProps.value < 0 ) setYear(0);
    if(yearProps.value > 2500 ) setYear(2500);
    if(monthProps.value==="") err=true;
    if(monthProps.value < 0 ) setMonth(0);
    if(monthProps.value > 12 ) setMonth(12);
    if(dayProps.value==="") err=true;
    if(dayProps.value < 0 ) setDay(0);
    if(dayProps.value > 31 ) setDay(31);
    if(titleProps.value==="") err=true;
    if(postProps.value==="") err=true;
    if(nameProps.value==="") err=true;

    setHasError(err);
  });

  const updateData = () => {
    setLoading(true);
    dekigotoUpdate({
      v4: id,
      year: yearProps.value,
      month: monthProps.value,
      day: dayProps.value,

      title: titleProps.value,
      description: descriptionProps.value,
      commander:commanderProps.value,
      post:postProps.value,
      name:nameProps.value,
      comment:commentProps.value,
      media,
      company,
    },setRet);
  }

  useEffect(()=>{
    if(ret.status==='OK'){
      setLoading(false);
      navigate('/dekigoto/data/' + ret.v4);
    }
  },[ret]);

  return (
    <div className="mainArea">
      <p>できごとに登録したい情報を入力してください。</p>
      
      <dl>
        <dt className="required">年月日</dt>
        <dd>
          <input type="number" min="0" max="2500" {...yearProps} /> 年　
          <input type="number" min="0" max="12" {...monthProps} /> 月　
          <input type="number" min="0" max="31" {...dayProps} /> 日　
        </dd>

        <dt className="required">タイトル</dt>
        <dd>
          <input type="text" {...titleProps} />
        </dd>

        <dt>説明文章①</dt>
        <dd>
          <TextareaAutosize  {...descriptionProps} />
        </dd>

        <dt>掲載場所</dt>
        <dd>
          <button onClick={openMediaDialog}>選択</button>
          <div className="list">
            {media.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
          </div>
        </dd>

        <dt>関連会社</dt>
        <dd>
          <button onClick={openCompanyDialog}>選択</button>
          <div className="list">
          {company.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
          </div>
        </dd>

        <dt>作業指示者</dt>
        <dd>
          <input type="text" {...commanderProps} />
        </dd>

        <dt className="required">最終更新者</dt>
        <dd className="editor">
          <div>
            所属・部署名<br />
            <input type="text" {...postProps} />
          </div>
          <div>
            氏名<br />
            <input type="text" {...nameProps} />
          </div>
        </dd>

        <dt>最終更新日</dt>
        <dd>
          {dekigoto.mdatetime ? dekigoto.mdatetime : '------/--/--'}
        </dd>

        <dt>コメント</dt>
        <dd>
          <TextareaAutosize  {...commentProps} />
        </dd>

      </dl>

      <div className="buttons">
        <LoadingButton loading={loading} disabled={ hasError } onClick={updateData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
          更新
        </LoadingButton>
      </div>

      <MediaSelectDialog open={mediaDialogOpen} onClose={closeMediaDialog} values={media}  />
      <CompanySelectDialog open={companyDialogOpen} onClose={closeCompanyDialog} values={company}  />
    </div>
   );
}
