import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Dekigoto.scss';
import DekigotoListImageImage from "./DekigotoListImageImage";

export default function DekigotoListImage({ list }) {

  const navigate = useNavigate();

  return (
    <div className="dekigotoListImage">
      {list.list.map((data, i) =>
        <DekigotoListImageImage key={i} ddata={data} />
      )}
    </div>
  );
}
