import { React, useState, useEffect, useCallback } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';


import './Company.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";
import CompanyEditMain2Data from "./CompanyEditMain2Data";

export default function CompanyEditMain2(props) {
  const { companyMetaUpdate, loadCompanyMetas } = useMyData();

  const [ id, setId ] = useState(props.id);
  const [loading,setLoading] = useState(false);
  const [ret, setRet] = useState(true); // 登録時の戻り値
  const [hasError, setHasError] = useState(true); // エラーチェック

  const [metaData,setMetaData] = useState([]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    setHasError(err);
  });

  let datas = [];
  const setInitDatas = (data) => {
    datas = data;
    datas.push({});
    setMetaData(datas);
  }

  useEffect(()=>{
    loadCompanyMetas(id,setInitDatas);
 },[]);


  const updateData = () => {
    setLoading(true);
    companyMetaUpdate({
      v4: id,
      meta: metaData
    },setRet);
  }

  useEffect(()=>{
    if(ret.status==='OK'){
      setLoading(false);
      loadCompanyMetas(id,setInitDatas);
    }
  },[ret]);

  const changeData = (e) => {
    datas = [...metaData];

    datas[e.num] = {
      title: e.title,
      description: e.description,
      comment: e.comment,
    }
    setMetaData(datas);
  }

  return (
    <div className="mainArea companyMain2">
      <p>下記項目はプルダウンメニューから選択可能です。</p>

      <div className="sample">
        <ul>
        <li>事業内容</li>
        <li>企業理念</li>
        <li>スローガン</li>
        <li>創業の理念</li>
        <li>お客様に向けてのメッセージ</li>
        <li>社是</li>
        <li>社員行動規範</li>
        <li>その他</li>
        </ul>
        <p>その他項目入力時は、任意の項目名を直接入力してください。</p>
      </div>
      
      {metaData.map((data,i) => 
        <CompanyEditMain2Data key={i} data={data} num={i} onChange={changeData} />
      )}

      <div className="buttons">
        <LoadingButton loading={loading} disabled={ hasError } onClick={updateData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
          登録
        </LoadingButton>
      </div>

    </div>
   );
}
