import { React, useState } from "react";
import { Button } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './My.scss';

export function MyButtonEdit(props) {

  return (
      <Button  {...props} 
        variant="contained" 
        className={"myedit " + (props.className ? props.className : '')} 
        onClick={props.onClick}
        startIcon={<ArrowDropDownIcon />}
      > {props.children}</Button>
  );
}

