import { React } from "react";

export default function ListAddIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.152" viewBox="0 0 20 15.152">
      <defs>
        <clipPath id="clip-path">
          <rect id="長方形_474" data-name="長方形 474" width="20" height="15.152" fill="none"/>
        </clipPath>
      </defs>
      <g id="グループ_10929" data-name="グループ 10929" clipPath="url(#clip-path)">
        <path id="パス_10096" data-name="パス 10096" d="M.909,1.818H16.667a.909.909,0,1,0,0-1.818H.909a.909.909,0,0,0,0,1.818" fill="#2e3d49"/>
        <path id="パス_10097" data-name="パス 10097" d="M.909,5.818H16.667a.909.909,0,1,0,0-1.818H.909a.909.909,0,0,0,0,1.818" transform="translate(0 0.848)" fill="#2e3d49"/>
        <path id="パス_10098" data-name="パス 10098" d="M11.039,8H.909a.909.909,0,1,0,0,1.818h10.13a.909.909,0,0,0,0-1.818" transform="translate(0 1.697)" fill="#2e3d49"/>
        <path id="パス_10099" data-name="パス 10099" d="M16.758,9.424H15.242V7.909a.909.909,0,1,0-1.818,0V9.424H11.909a.909.909,0,0,0,0,1.818h1.515v1.515a.909.909,0,1,0,1.818,0V11.242h1.515a.909.909,0,1,0,0-1.818" transform="translate(2.333 1.485)" fill="#2e3d49"/>
      </g>
    </svg>
 );
}
