import { React } from "react";

export default function ThreeBarIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
      <g id="並び替えアイコン" transform="translate(-4368.5 -4670.5)">
        <line id="線_10" data-name="線 10" x2="17" transform="translate(4369.5 4671.5)" fill="none" stroke="#b2bac2" strokeLinecap="round" strokeWidth="2" />
        <line id="線_11" data-name="線 11" x2="17" transform="translate(4369.5 4678.5)" fill="none" stroke="#b2bac2" strokeLinecap="round" strokeWidth="2" />
        <line id="線_12" data-name="線 12" x2="17" transform="translate(4369.5 4685.5)" fill="none" stroke="#b2bac2" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  );
}
