import { React } from "react";

export default function FileIcon() {

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="20.596" height="26.874" viewBox="0 0 20.596 26.874">
  <g id="グループ_10962" data-name="グループ 10962" transform="translate(0 0)" >
    <path id="パス_10101" data-name="パス 10101" d="M16.1,25.374H4.5a3,3,0,0,1-3-3V4.5a3,3,0,0,1,3-3h8.079a3,3,0,0,1,2.388,1.183L18.483,7.3A3,3,0,0,1,19.1,9.121V22.374A3,3,0,0,1,16.1,25.374Z" fill="none" stroke="#222e39" strokeMiterlimit="10" strokeWidth="3"/>
  </g>
</svg>
 );
}
