import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";

import './FilesDialog.scss';
import { AppBar, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import FileDataView from "./FileDataView";
import { MyButtonClose } from "./MyButtonClose";
import FileDataViewKeep from "./FileDataViewKeep";


export default function FilesDialog({
  files = [], 
  open = false, 
  onClose = f => f, 
  target = 'attach',
  deleteItem = f => f,
}) {
  const { fileDownloadZip, downloadlist, setDownloadlist } = useMyData();

  const [dopen, setDOpen] = useState(false);
  const [keep, setKeep] = useState([]); // 掲載場所
  const [ready, setReady] = useState(true);

  useEffect(() => {
    setDOpen(open);
  }, [open]);

  const handleClose = () => {
    setDOpen(false);
    onClose();
  };

  const keepImage = (e) => {
    // 重複排除
    const set = new Set([
      ...keep,
      e.target.value
    ]);
    setKeep([...set]);
  }
  const removeImage = (e) => {
    setKeep(keep.filter((item) => {
      return item !== e.target.value
    }));
  }
  const adddownload = (e) => {
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return downloadlist.indexOf(item.id) < 0 && keep.indexOf(item.id) >= 0;
      })
    ]);
    setDownloadlist([...set]);
  }
  const downloadkeepzip = (e) => {
    fileDownloadZip(keep, setReady);
  }
  
  const clearList = (e) => {
    setDownloadlist([]);
    handleClose();
  }

  return (
    <>
      <Dialog
        open={dopen}
        onClose={handleClose}
        className="filesDialog"
      >
        <AppBar sx={{ position: 'relative' }} color="subcolor">
          <Toolbar sx={{ height: "89px" }}>
            <Typography sx={{ ml: 2, flex: 1, fontSize: "32px" }} variant="h6" component="div">
              {target == 'download' ? 'ダウンロードリスト' : '付属データ' }
            </Typography>
            <MyButtonClose
              edge="end"
              onClick={handleClose}
              aria-label="close" />
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className="content">
            {target == 'download' &&
              <Button
                className={keep.length > 0 ? "listClear withKeep" : "listClear" }
                variant="contained"
                color="accent"
                onClick={clearList}
                disabled={!ready}
              >
                ダウンロードリストを空にする
              </Button>
            }

            <div className={"items" + (keep.length > 0 ? " haskeep" : "")}>
              {files.map((data, i) => <FileDataView key={data.id} data={data} mode="dialog" target={target} keepImage={keepImage} deleteItem={deleteItem} />)}
            </div>
            {keep.length > 0 &&
            <div className="keeps">
              <h5>{target == 'download' ? '一括ダウンロードリスト' : 'キープリスト' }</h5>
              <div className="keepitems">
                {files.filter((item) => {
                  return keep.indexOf(item.id) >= 0;
                }).map((data, i) => <FileDataViewKeep key={data.id} data={data} removeImage={removeImage} />)}
              </div>

              <div className="bottomButtons">
                { target == 'attach' &&
                <Button
                  variant="outlined"
                  color="outlinedbutton"
                  onClick={adddownload}
                >
                  全てダウンロードリストに追加
                </Button>
                }
                <Button
                  variant="contained"
                  color="accent"
                  onClick={downloadkeepzip}
                  disabled={!ready}
                >
                  {ready ? "一括ダウンロード" : "準備中"}
                </Button>
              </div>

            </div>
            }
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

}
