import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Company.scss';
import DekigotoListImageImage from "./DekigotoListImageImage";
import CompanyListItemPeace from "./CompanyListItemPeace";

export default function CompanyListItem({ list }) {

  const navigate = useNavigate();

  return (
    <div className="companyListItem">
      {list.list.map((data, i) =>
        <CompanyListItemPeace key={i} ddata={data} />
      )}
    </div>
  );
}
