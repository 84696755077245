import { React } from "react";

export default function ArrowDownIcon() {

  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.338" viewBox="0 0 13 13.338">
    <path id="矢印アイコン" d="M12.46,7.546l.661.661a.712.712,0,0,1,0,1.009L7.337,15a.712.712,0,0,1-1.009,0L.541,9.216a.712.712,0,0,1,0-1.009L1.2,7.546a.715.715,0,0,1,1.021.012L5.64,11.145V2.589a.713.713,0,0,1,.714-.714h.953a.713.713,0,0,1,.714.714v8.556l3.417-3.587A.71.71,0,0,1,12.46,7.546Z" transform="translate(-0.331 -1.875)" fill="#2f3d4a"/>
  </svg>
 );
}
