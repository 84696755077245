import { React, useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Dekigoto.scss';
import DekigotoListTileTile from "./DekigotoListTileTile";

export default function DekigotoListTile({ list }) {

  const navigate = useNavigate();

  return (
    <>
      {list.list.map((data, i) =>
        <DekigotoListTileTile key={i} data={data} />
      )}
    </>
  );
}
