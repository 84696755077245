import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import './TopAuth.scss';
import { Backdrop, CircularProgress } from "@mui/material";

export default function TopAuth() {
  const { data, myAuth } = useMyData();

  const [idProps, resetId] = useInput(""); // ログインID
  const [passwordProps, resetPassword] = useInput(""); // パスワード
  const [postProps, resetPost] = useInput(""); // 所属・部署名
  const [nameProps, resetName] = useInput(""); // 氏名

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [authStatus, setAuthStatus] = useState(""); // 認証の戻り値

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(idProps.value==="") err=true;
    if(passwordProps.value==="") err=true;
    if(nameProps.value==="") err=true;
    if(postProps.value==="") err=true;

    setHasError(err);
  });


  // ログイン
  const submit = (e) => {
    e.preventDefault();
    setAuthStatus("WAITING");
    myAuth(idProps.value, passwordProps.value,nameProps.value,postProps.value, setAuthStatus);
  }


  if (data.loading) {
    return (
      <>
      <Backdrop style={{zIndex:1000,backgroundColor:'#fff'}} transitionDuration={0} open={true}>
        <CircularProgress />
      </Backdrop>
      </>
    );
  } else {
    return (
      <div className="login">
        <form onSubmit={submit}>
          <h1>ログイン</h1>
          <table >
          <tbody>
            <tr>
              <th>ID</th>
              <td><input type="text" {...idProps} size="50" autoComplete="username" required /></td>
            </tr>
            <tr>
              <th>パスワード</th>
              <td><input type="password" {...passwordProps} autoComplete="current-password" size="50" required /></td>
            </tr>
            <tr>
              <th>所属・部署名</th>
              <td><input type="text" {...postProps} size="50" required /></td>
            </tr>
            <tr>
              <th>氏名</th>
              <td><input type="text" {...nameProps} size="50" required /></td>
            </tr>
          </tbody>
          </table>

          <div className="msg">
          { authStatus==='WAITING' && <>認証中</> }
          { authStatus==='NO USER' && <><span className="notice">IDまたはパスワードが正しくありません。</span></> }
          { authStatus==='PASSWORD ERROR' && <><span className="notice">IDまたはパスワードが正しくありません。</span></> }
          { authStatus==='TOO MATCH FAILS' && <><span className="notice">IDまたはパスワードが正しくありません。</span></> }
          </div>

          <div className="button">
            <input value="ログイン" type="submit" id="next" disabled={ hasError } />
          </div>

        </form>
      </div>
    );
  }
}
