import { React, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, MenuItem, Select } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './My.scss';

export function MySelectSortCompany({value = "mdatetime", onChange = f => f}) {

  const theme = createTheme(
    {
      palette: {
        primary: { 
          main: '#26C1C9' ,
          dark: '#26C1C9' ,
        },
      },
    },
  );

 
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="filled" className="my" sx={{ m: 0, minWidth: 120 }}>
        <Select
          value={value}
          onChange={onChange}
        >
          <MenuItem value="mdatetime">
            更新日順 <ArrowUpwardIcon fontSize="18px" />
          </MenuItem>
          <MenuItem value="mdatetime desc">
            更新日順 <ArrowDownwardIcon fontSize="18px" />
          </MenuItem>
          <MenuItem value="foundation_date">
            設立年月日順 <ArrowUpwardIcon fontSize="18px" />
          </MenuItem>
          <MenuItem value="foundation_date desc">
            設立年月日順 <ArrowDownwardIcon fontSize="18px" />
          </MenuItem>
          <MenuItem value="yomi">
            企業名ふりがな <ArrowUpwardIcon fontSize="18px" />
          </MenuItem>
          <MenuItem value="yomi desc">
            企業名ふりがな <ArrowDownwardIcon fontSize="18px" />
          </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

