import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import './Menu.scss';

export default function Menu() {
  const { data,signOut } = useMyData();



  return (
    <div className="menu">
      <ul>
      <li><Link to="/dekigoto/list" className={data.menu==='dekigotolist' ? 'active':''}>できごと検索</Link></li>
      <li><Link to="/company/list" className={data.menu==='companylist' ? 'active':''}>グループ企業一覧</Link></li>
      <li><Link to="/publication/list" className={data.menu==='publicationlist' ? 'active':''}>媒体一覧</Link></li>
      {data.role>=3 &&
      <>
      <li><Link to="/dekigoto/add" className={data.menu==='dekigoto' ? 'active':''}>できごと登録</Link></li>
      <li><Link to="/company/add" className={data.menu==='company' ? 'active':''}>グループ企業登録</Link></li>
      <li><Link to="/publication/add" className={data.menu==='publication' ? 'active':''}>媒体登録</Link></li>
      </>
      }
      </ul>
    </div>
 );
}
