import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListAddIcon from "./Icons/ListAddIcon";
import MinusIcon from "./Icons/MinusIcon";
import PlusIcon from "./Icons/PlusIcon";
import { useMyData } from './MyProvider';

import './Publication.scss';
import PublicationListMediaPub from "./PublicationListMediaPub";

export default function PublicationListMedia({
  data, 
  handleChange = f => f,
  handleOpenClose = f => f,
  updateFiles = f => f,
}) {

  const { downloadlist, setDownloadlist } = useMyData();

  const [allChecked, setAllChecked] = useState(0); // 全媒体を選択／解除
  const [allSelect, setAllSelect] = useState(0); // 全媒体を選択／解除
  const [count, setCount] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [pubs, setPubs] = useState([]);

  useEffect(() => {
    setAllChecked(data.checked);
  }, [data]);

  useEffect(() => {
    setPubs(data.pubs);
  }, [data]);


  useEffect(() => {
    const checkedCount
      = pubs.filter(item => {
          return item.checked
        }).length

    setCount(checkedCount);

    const fcount = pubs.reduce( (sum, item) => {
      if ( item.checked ) {
        return sum + item.files.length;
      } else {
        return sum;
      }
    }, 0);

    setFileCount(fcount);
    

    if(pubs.length == checkedCount) {
      setAllSelect(true);
    } else {
      setAllSelect(false);
    }

  }, [pubs]);

  const handleChangeLocal = (event) => {
    handleChange(data.v4, event.target.checked ? 1 : 0)
    //setAllChecked(event.target.checked ? 1 : 0);
    //console.log("allChecked HandleChange!!!!!");
    /*
    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: event.target.checked
        }
      })
    )
    */
  }

  const handleChangePub = (v4, val) => {
    setPubs(
      pubs.map(item => {
        if(item.v4 == v4) {
          return {
            ...item,
            checked: val,
          }
        } else {
          return item
        }
      })
    );

  }


  const updateSelection = (val) => {
    setPubs(
      pubs.map(item => {
        return {
          ...item,
          checked: val,
        }
      })
    );
  }

  const handleClear = (e) => {
    setAllSelect(false);
    updateSelection(0);
  }

  const handleSelect = (e) => {
    setAllSelect(true);
    updateSelection(1);
  }

  const handleOpenCloseLocal = (e) => {
    console.log(handleOpenCloseLocal);
    handleOpenClose(data.v4, ! data.open);
  }

  const updateFilesLocal = (v4, files) => {
    updateFiles(data.v4, v4, files);
  }



  const adddownload = (e) => {
    let files = [];
    pubs.forEach(item => {
      if(item.checked) {
        files =[...files, ...item.files];
      }
    })
    adddownloadFiles(files);
  }

  const adddownloadFiles = (files) => {
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return true;
      })
    ]);
    setDownloadlist([...set]);
  }


  return (
    <>
      <div data-id={data.v4} className="listMedia">
        <div className="titleArea">
          <div className="iconArea" onClick={handleOpenCloseLocal}>
            { !data.open && <PlusIcon /> }
            { data.open && <MinusIcon /> }
          </div>
          <FormControlLabel control={
            <Checkbox 
              checked={allChecked==1}
              onChange={handleChangeLocal}
              indeterminate={allChecked==-1}
              sx={{
                color: "#4D4F5C",
                '&.Mui-checked': {
                  color: "#26C1C9",
                },
                '&.MuiCheckbox-indeterminate': {
                  color: "#26C1C9",
                },
              }} />
            } label={data.name} />

        </div>
        { data.open && 
          <>
            {pubs.length > 0 &&
            <div className="selectArea">
              <div className="message">
                現在 {count} 件の媒体が選択されています
                {allSelect==1 && <span className="selectall" onClick={handleClear} data-checked={false}>全ての媒体を選択解除</span>}
                {allSelect< 1 && <span className="selectall" onClick={handleSelect} data-checked={true}>全ての媒体を選択</span>}
              </div>
              {fileCount > 0 &&
                <Button className="addList" onClick={adddownload}><ListAddIcon /> ダウンロードリストに追加</Button>
              }
            </div>
            }
            {pubs.length>0 && pubs.map((d, i) => 
              <PublicationListMediaPub data={d} key={i} handleChange={handleChangePub} updateFiles={updateFilesLocal} />
            )}
          </>
        }
      </div>
    </>

  );
}
