import { React, useEffect } from "react";
import Header from "./Header";

import { useMyData } from './MyProvider';
import TopAuth from "./TopAuth";
import './App.scss';
import Menu from "./Menu";
import Main from "./Main";
import useLocationChange from "./useLocationChange";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    subcolor: {
      main: '#BDECEF',
      contrastText: '#2F3D4A',
    },
    accent: {
      main: '#F88B5E',
      contrastText: '#fff',
    },
    outlinedbutton: {
      main: '#2F3D4A',
      contrastText: '#fff',
    },
    buttoncolor: {
      main: '#DBE2E8',
      contrastText: '#2F3D4A',
    },
    monobuttoncolor: {
      main: '#707070',
      contrastText: '#707070',
    },
    
  },
});


function App() {
  const { data,signOut,authCheck } = useMyData();

  useLocationChange((location) => {
    authCheck();
  });


  if(data.token && !data.loading) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Menu />
        <Main />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <TopAuth />
      </ThemeProvider>
    );
  }
}

export default App;
