import { Fragment, React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";
import { Container, Draggable } from 'react-smooth-dnd';

import './MediaSelectDialogEditable.scss';
import { AppBar, Checkbox, FormControlLabel, Toolbar, Typography } from "@mui/material";
import { MyButtonClose } from "./MyButtonClose";
import TrashIcon from "./Icons/TrashIcon";
import ThreeBarIcon from "./Icons/ThreeBarIcon";
import PencilIcon from "./Icons/PencilIcon";
import ConfirmDialog from "./ConfirmDialog";
import MediaEditDialog from "./MediaEditDialog";
import {arrayMoveImmutable} from "array-move";

export default function MediaSelectDialogBig(props) {
  const { setMedias } = useMyData();

  const [open, setOpen] = useState(false);
  const [media,setMedia] = useState([]); // 掲載場所
  const [allChecked, setAllChecked] = useState(0);

  const [count, setCount] = useState(0);

  const [confirmSelectedDialogOpen, setConfirmSelectedDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmId, setConfirmId] = useState(0);

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleYes = () => {
    setOpen(false);
    props.onClose(media);
  };
  
  const setData = (e) => {
    setMedia(
      media.map(item => {
        if (Number(item.id) === Number(e.target.value)) {
          return {
            ...item,
            checked: e.target.checked
          }
        } else {
          return item;
        }
      })
    )
  }

  const handleChange = (event) => {
    setAllChecked(event.target.dataset.checked ? 1 : 0);
    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: event.target.dataset.checked
        }
      })
    )
  }

  const handleClear = () => {
    setAllChecked(false);
    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    )
  }

  useEffect(() => {
    setMedia(props.values);
  }, [props.values]);

  useEffect(() => {
    const comp = media.filter(item => {return item.checked}).length;

    if(media.length == comp) {
      setAllChecked(1);  
    } else if(comp==0) {
      setAllChecked(0);  
    } else {
      setAllChecked(-1);  
    }
  }, [media]);
  
  useEffect(() => {
    setCount(
      media.filter(item => {
        return item.checked
      }).length
    )
  }, [media]);


  const closeSelectedDialog = (e) => {
    setConfirmSelectedDialogOpen(false);
  }
  const yesSelectedDialog = (e) => {
    setConfirmSelectedDialogOpen(false);

    setMedias(
      media.filter(item => {return ! item.checked})
      ,setMedia
    );
  }
  const deleteSelected = (e) => {
    setConfirmSelectedDialogOpen(true);
  }

  const closeDialog = (e) => {
    setConfirmDialogOpen(false);
  }
  const yesDialog = (e) => {
    setConfirmDialogOpen(false);

    setMedias(
      media.filter(item => {return item.id != Number(confirmId)})
      ,setMedia
    );

  }
  const deleteItem = (e) => {
    setConfirmTitle(e.target.dataset.name);
    setConfirmId(e.target.dataset.id);
    setConfirmDialogOpen(true);
  }

  
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
  }
  const yesMediaDialog = (e) => {
    setMediaDialogOpen(false);
    setConfirmTitle("");

    if(confirmId > 0) {
      setMedias(
        media.map(item => {
          if (Number(item.id) === Number(confirmId)) {
            return {
              ...item,
              name: e
            }
          } else {
            return item;
          }
        })
        ,setMedia
      );
    } else {
      setMedias(
        [...media
          ,{
          checked: 0,
          id: 0,
          name: e,
          v4: '',
        }]
      ,setMedia);
    }
  }
  const editItem = (e) => {
    setConfirmTitle(e.target.dataset.name);
    setConfirmId(e.target.dataset.id);
    setMediaDialogOpen(true);
  }
  const newItem = (e) => {
    setConfirmTitle("");
    setConfirmId(0);
    setMediaDialogOpen(true);
  }
  
  const onDrop = (dropResult) => {
    const { removedIndex, addedIndex } = dropResult;
    console.log(dropResult);
    setMedia(
      arrayMoveImmutable(media, removedIndex, addedIndex)
    );
    setMedias(
      arrayMoveImmutable(media, removedIndex, addedIndex)
      ,setMedia
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleYes}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mediaDialogEditable"
        maxWidth={false}
      >
        
        <Toolbar sx={{ height: "89px" }}>
          <Typography sx={{ ml: 2, flex: 1, fontSize: "32px" }} variant="h6" component="div">
            掲載場所の追加
          </Typography>
          <MyButtonClose
            edge="end"
            onClick={handleYes}
            aria-label="close" />
        </Toolbar>
        
        <div className="dialgHead">

          <div className="description">
            こちらで掲載場所の新規項目追加と並び替えが行えます。
          </div>
          <div className="tableHead">
            <div></div>
            <div></div>
            <div>媒体名称</div>
          </div>
          <div className="tableHeadAll">
            <div>
              現在 {count} 件の媒体が選択されています
              {allChecked==1 && <span onClick={handleClear} data-checked={false}>全ての媒体を選択解除</span>}
              {allChecked< 1 && <span onClick={handleChange} data-checked={true}>全ての媒体を選択</span>}
            </div>
            {count > 0 && <button onClick={deleteSelected}><TrashIcon />まとめて削除</button>}
          </div>
        </div>

        <DialogContent>
          <div className="selects">
            <div className="items">
              <Container 
                onDrop={onDrop}
                lockAxis="y"
                dragHandleSelector=".dragHandle"
                dragClass="drag"
              >
                {media.map((data,i) => 
                  <Draggable key={i} className="itemArea">
                    <div className="dragHandle">
                      <ThreeBarIcon />
                    </div>
                      <FormControlLabel key={i} className="item" control={
                      <Checkbox 
                        checked={data.checked ? true : false}
                        onChange={setData}
                        value={data.id}
                        sx={{
                          color: "#4D4F5C",
                          '&.Mui-checked': {
                            color: "#26C1C9",
                          },
                        }} />
                      } label={data.name} />
                    <button onClick={editItem} data-id={data.id} data-name={data.name}><PencilIcon /> 編集</button>
                    <button onClick={deleteItem} data-id={data.id} data-name={data.name}><TrashIcon /> 削除</button>
                  </Draggable>
                )}
              </Container>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={newItem}>
            新規追加
          </button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog open={confirmSelectedDialogOpen} onClose={closeSelectedDialog} onYes={yesSelectedDialog} title="確認" msg={"選択された" + count + "媒体を削除します"} />
      <ConfirmDialog open={confirmDialogOpen} onClose={closeDialog} onYes={yesDialog} title="確認" msg={"「" + confirmTitle + "」を削除します"} />
      <MediaEditDialog open={mediaDialogOpen} onClose={closeMediaDialog} onYes={yesMediaDialog} title="" value={confirmTitle} mainbutton={confirmId ? "更新" : "新規追加"} msg={confirmId ? "媒体の名称を入力してください。" : "追加媒体の名称を入力してください。"} />
    </>
 );

}
