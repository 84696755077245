import React, { createContext, useState, useContext } from "react";

const MyContext = createContext();
export const useMyData = () => useContext(MyContext);

export default function MyProvider({ children }) {
  const [data, setData] = useState({});
  const [param, setParam] = useState({});
  const [cparam, setCParam] = useState({});
  const [downloadlist, setDownloadlist] = useState([]);

  const loadJSON = (key) => {
    if (key) {
    } else {
      return {};
    }
    const data = localStorage.getItem(key);
    if (typeof data === "undefined" || data === "undefined") {
      return {};
    } else {
      return JSON.parse(data)
    }
  };
  const saveJSON = (key, data) => localStorage.setItem(key, JSON.stringify(data));

  // TOKENが有効なのか確認
  const authCheck = async () => {
    const token = loadJSON("token");

    const loading = loadJSON("loading");
    if (loading == 1) {
      return;
    }
    saveJSON("loading", 1);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setData({
      ...data,
      "loading": true,
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'authcheck',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      saveJSON("loading", 0);
      if (d) {
        if (d.status) {
          if (d.status === "OK" && d.token) {
            saveJSON("token", d.token);
            setData({
              ...data,
              "loading": false,
              "token": d.token,
              "name": d.name,
              "post": d.post,
              "user": d.user,
              "role": d.role,
              "lastlogin": d.lastlogin,
            })
          } else {
            setData({
              ...data,
              "token": undefined,
              "name": undefined,
              "post": undefined,
              "user": undefined,
              "lastlogin": undefined,
            })
          }
        } else {
          signOut(0);
        }
      } else {
        signOut(1);
      }
    } catch (error) {
      console.log(error);
      saveJSON("loading", 0);
    }
  }

  // APIを叩いてログイン
  const myAuth = async (id, pw, name, post, target) => {
    if (id && pw) {
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'signin',
          {
            headers: {
              'Content-type': 'application/json'
            },
            method: "post",
            body: JSON.stringify({ id, pw, name, post }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if (data) {
          if (data.status) {
            target(data.status);
          }
          if (data.token) {
            saveJSON("token", data.token);
            saveJSON("loading", 0);
            setData({
              ...data,
              "token": data.token,
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // ログアウト
  const signOut = (redirect = 1) => {
    setData({
      ...data,
      "token": undefined,
      "name": undefined,
      "post": undefined,
      "user": undefined,
    });
    saveJSON("token", undefined);
    saveJSON("loading", 0);
    if (redirect) {
      window.location = "/";
    }
  }

  // MENUセット
  const setMenu = (menu) => {
    setData({
      ...data,
      menu,
    })
  }


  // 掲載場所取得
  const getMedias = async (target, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'medias/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.list) {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 掲載場所取得 企業データ用
  const getCompanyMedias = async (target, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company_medias/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.list) {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  

  // 掲載場所登録
  const setMedias = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'medias/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 関連会社取得
  const getCompanies = async (target = f => f, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'companies/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.list) {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const companyExport = async (target = f => f) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'companies/export',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const companyCategories = (company) => {
    let cat = [];
    cat = company.map((data, i) => {
      return {
        category: data.category,
        category_name: data.category_name,
        select: false,
      }
    });
    cat = Array.from(
      new Map(cat.map((e) => [e.category, e])).values()
    );
    return cat;
  }

  // 媒体取得
  const getPubMedias = async (target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'pub_medias/',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.list) {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 媒体登録
  const setPubMedias = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'pub_medias/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // できごと取得
  const getDekigoto = async (target, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // できごと登録
  const dekigotoAdd = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/add',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // できごと更新
  const dekigotoUpdate = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // できごと一覧
  const getDekigotoList = async (params, target, endtarget) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setParam({
      ...param,
      ...params
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify({
            word: params.word, // 検索ワード
            media: params.media.filter(item => item.checked).map(item => { return item.id }), // 掲載場所
            company: params.company.filter(item => item.checked).map(item => { return item.id }), // 関連会社
            syear: params.syear, // 年
            smonth: params.smonth, // 月
            eyear: params.eyear, // 年
            emonth: params.emonth, // 月
            dateS: dateFormat(params.dateS), // 更新日S
            dateE: dateFormat(params.dateE), // 更新日E
            file: params.file ? 1 : 0, // 付属データあり
            type: params.type,
            count: params.count,
            sort: params.sort,
            page: params.page, // ページ
          }),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
        endtarget(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // できごと件数
  const getDekigotoCount = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setParam({
      ...param,
      ...params
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify({
            word: params.word, // 検索ワード
            media: params.media.filter(item => item.checked).map(item => { return item.id }), // 掲載場所
            company: params.company.filter(item => item.checked).map(item => { return item.id }), // 関連会社
            syear: params.syear, // 年
            smonth: params.smonth, // 月
            eyear: params.eyear, // 年
            emonth: params.emonth, // 月
            dateS: dateFormat(params.dateS), // 更新日S
            dateE: dateFormat(params.dateE), // 更新日E
            file: params.file ? 1 : 0, // 付属データあり
            type: params.type,
            count: params.count,
            sort: params.sort,
            page: params.page, // ページ
            countonly: 1,
          }),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data.count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業取得
  const getCompany = async (target, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業登録
  const companyAdd = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/add',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業更新
  const companyUpdate = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業更新 基本情報②
  const companyMetaUpdate = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/meta/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業更新 沿革
  const companyHistoriesUpdate = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/histories/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業一覧
  const getCompanyList = async (params, target, endtarget) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setCParam({
      ...cparam,
      ...params
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'company/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify({
            word: params.word, // 検索ワード
            categories: params.categories.filter(item => item.active).map(item => { return item.category }), 
            count: params.count,
            sort: params.sort,
            page: params.page, // ページ
          }),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
        endtarget(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  // 媒体登録
  const publicationAdd = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/add',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 媒体更新
  const publicationUpdate = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/update',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 媒体取得
  const getPublication = async (target, id = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/' + id,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 媒体一覧
  const getPublicationList = async (params, target, endtarget) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify({
            syear: params.syear, // 年
            smonth: params.smonth, // 月
            eyear: params.eyear, // 年
            emonth: params.emonth, // 月
          }),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data.list);
        endtarget(false);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const dateFormat = (d) => {
    if (d) {
      return d.format('YYYY-MM-DD');
    } else {
      return '';
    }
  }

  // できごと File Upload
  const postDekigotoFile = async (v4, file, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);
    formData.append('filepath', file.path);

    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/' + v4 + '/file',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // できごと Files
  const loadDekigotoFiles = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'dekigoto/' + v4 + '/files',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d.files);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業情報 File Upload
  const postCompanyFile = async (v4, file, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);
    formData.append('filepath', file.path);

    try {
      const response = await fetch(
        API_ENDPOINT + 'company/' + v4 + '/file',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業情報 Files
  const loadCompanyFiles = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'company/' + v4 + '/files',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d.files);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業情報 基本情報②
  const loadCompanyMetas = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'company/' + v4 + '/metas',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d.metas);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 企業情報 沿革
  const loadCompanyHistories = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'company/' + v4 + '/histories',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d.histories);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // File Update
  const fileUpdate = async (v4, file, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'file/' + v4 + '/update',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify(file),
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // File Delete
  const fileDelete = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'file/' + v4 + '/delete',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler({ status: 'DELETED' });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Publication Delete Dir
  const publicationRmdir = async (v4, path, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/rmdir',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify({ v4: v4, path: path }),
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler({ status: 'DELETED' });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Files To Zip
  const fileDownloadZip = async (v4s, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setHangler(false);

    try {
      const response = await fetch(
        API_ENDPOINT + 'file/zip',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify({ files: v4s }),
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          const downloadTag = document.createElement('a');
          downloadTag.href = API_ENDPOINT + 'file/' + d.v4 + '/download/' + token;
          downloadTag.download = 'data.zip';
          downloadTag.click();
          setHangler(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 媒体 File Upload
  const postPublicationFile = async (v4, file, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);
    formData.append('filepath', file.path);

    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/' + v4 + '/file',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 媒体 Files
  const loadPublicationFiles = async (v4, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(
        API_ENDPOINT + 'publication/' + v4 + '/files',
        {
          headers: {
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d.files);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 郵便番号
  const zip2addr = async (setPref, setAddr ,  zip = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'kenall/' + zip,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.addr1) {
        setPref(data.addr1);
        setAddr(data.addr2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <MyContext.Provider value={{
      loadJSON,
      data,
      param, setParam,
      cparam, setCParam,
      downloadlist, setDownloadlist,
      myAuth,
      authCheck,
      signOut,

      getMedias,
      getCompanyMedias,
      setMedias,
      getPubMedias,
      setPubMedias,
      getCompanies,
      companyCategories,
      setMenu,
      dekigotoAdd,
      dekigotoUpdate,
      getDekigoto,
      getDekigotoList,
      getDekigotoCount,
      companyAdd,
      companyUpdate,
      companyMetaUpdate,
      companyHistoriesUpdate,
      companyExport,
      getCompanyList,
      getCompany,
      publicationAdd,
      publicationUpdate,
      getPublication,
      getPublicationList,

      postDekigotoFile,
      loadDekigotoFiles,
      postCompanyFile,
      loadCompanyFiles,
      loadCompanyMetas,
      loadCompanyHistories,
      postPublicationFile,
      loadPublicationFiles,
      fileUpdate,
      fileDelete,
      publicationRmdir,
      fileDownloadZip,
      zip2addr,
    }}>
      {children}
    </MyContext.Provider>
  );
}
