import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useOutsideHandle } from "./hooks";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, TextareaAutosize } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './Company.scss';
import { Label } from "@mui/icons-material";

export default function CompanyDataStake({values = [], onChange = f => f}) {
  const wrapperRef = useRef(null);

  const [selected,setSelected] = useState(0); // 選択されているもの
  const [editid,setEditid] = useState(0); // 編集用に表示されているもの
  const [capitalProps,,setCapital] = useInput("","number"); // 資本金
  const [capitalYearProps,,setCapitalYear] = useInput("0000","number"); // 資本金年
  const [capitalMonthProps,,setCapitalMonth] = useInput("00","number"); // 資本金月
  const [capitals,setCapitals] = useState(values);

  useEffect(()=>{
    setCapitals(values);
  },[values]);

  const outsideclick = () => {
    setSelected(0);
  }
  useOutsideHandle(wrapperRef , outsideclick);

  const add = () => {
    const data = {
      amount : Number(capitalProps.value),
      year   : Number(capitalYearProps.value),
      month : Number(capitalMonthProps.value),
    };

    let datas = [...capitals];

    if ( editid > 0 ) {
      datas[editid - 1] =data;
    } else {
      datas.push(data);
    }

    let result = datas.sort(function(a, b) {
      return (a.year * 100 + a.month < b.year * 100 + b.month) ? -1 : 1;
    });

    setCapitals(result);
    setEditid(0);
    setSelected(0);
  }

  const select = (e) => {
    setSelected(e.target.dataset['i']);
  }
  const editmode = () => {
    const data = capitals[selected - 1];
    setCapital(data.amount);
    setCapitalYear(data.year);
    setCapitalMonth(data.month);
    setEditid(selected);
  }
  const del = () => {
    const result = capitals.filter(function( data,i ) {
      return selected - 1 == i ? false : true;
    });
    setCapitals(result);

    setEditid(0);
    setSelected(0);
  }

  useEffect(()=>{
    onChange(capitals);
  },[capitals])

  return (
        <>
          <div className="flex">
            <label>
              <input className="company input stake" {...capitalProps} /> %
            </label>
          </div>
          <input className="company" type="number" min="0" max="2500" {...capitalYearProps} /> 年　
          <input className="company" type="number" min="0" max="12" {...capitalMonthProps} /> 月末　

          <div className="companyEdit" ref={wrapperRef}>
            <div className="companyEditButtons">
              <button className="add" onClick={add}>{editid ? '更新' : '追加'} <ArrowDropDownIcon /></button>
              <button className="edit" disabled={selected == 0} onClick={editmode}>編集 <ArrowDropUpIcon /></button>
              <button className="delete" disabled={selected == 0} onClick={del}>削除</button>
            </div>

            <div className="companyEditList">
              <ul>
                {capitals.map((data,i) => <li key={i+1} 
                                              className={i+1==selected ? 'selected' : ''} 
                                              data-i={i+1} 
                                              onClick={select}
                                          >{data.amount.toLocaleString()}% {data.year}年 {data.month}月末</li> )}
              </ul>
            </div>

          </div>
        </>

 );
}
