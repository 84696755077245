import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import { Badge } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Dekigoto.scss';
import FileDataView from "./FileDataView";

export default function DekigotoListImageImage({ddata }) {
  const { data, loadDekigotoFiles, downloadlist, setDownloadlist } = useMyData();

  const navigate = useNavigate();

  const [files, setFiles] = useState([]); // Files
  const [mainfile, setMainFile] = useState([]); // MainFiles

  const jumpPage = (e) => {
    navigate('/dekigoto/detail/' + e.currentTarget.dataset.id);
  }

  let ffiles = [];
  useEffect(() => {
    loadDekigotoFiles(ddata.v4, setInitFiles);
  }, []);

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }

  useEffect(() => {
    let mainFile = [];
    files.forEach(ele => {
      if(ele.mainimg==1) {
        mainFile.push(ele);
      }
    });
    setMainFile(mainFile);
  },[files]);

  const adddownload = (e) => {
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return downloadlist.indexOf(item.id) < 0 && ddata.fileid==item.id;
      })
    ]);
    setDownloadlist([...set]);
  }



  return (
      <>
        {ddata && 
        <>
          <div className="image">
            <div className="imageArea">
              {files.map((idata,i) => 
                <>
                  {idata.id==ddata.fileid && idata.nodownload == 0 && data.role >= 2 && <button onClick={adddownload}>ダウンロードリストに追加</button>}
                  <FileDataView key={idata.id} data={idata} mode="image" show={idata.id==ddata.fileid} />
                </> )}
            </div>

            <div onClick={jumpPage} data-id={ddata.v4} className="title">{ddata.title}</div>
          </div>
        </>
        }
      </>

  );
}
