import { React, useState, useEffect, useCallback } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { Autocomplete, CircularProgress, TextField, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';


import './Company.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";

export default function CompanyEditMain2Data({num, data, onChange = f => f}) {

  const [ description,, setDescription ] = useInput(data ? data.description || '' : '');
  const [ comment,, setComment ] = useInput(data ? data.comment || '' : '');

  const [titleProps,,setTitle] = useInput(data ? data.title || '' : ''); // 項目名
  const titles = [
    '事業内容',
    '企業理念',
    'スローガン',
    '創業の理念',
    'お客様に向けてのメッセージ',
    '社是',
    '社員行動規範',
    'その他',
  ];

  useEffect(()=>{
    onChange({
      num: num,
      title: titleProps.value,
      description: description.value,
      comment: comment.value,
    });
  },[description.value, comment.value, titleProps.value]);

  const titleUpdate = (e, newValue) => {
    if (typeof newValue === 'string') {
      setTitle(newValue);
    }
  }

  return (
    <div className="companyMain2Data">

      <div className="data">
        <div>
          <h3>項目名</h3>
          <Autocomplete
                freeSolo
                className="select"
                options={titles}
                renderInput={(params) => <TextField {...params} variant="filled" />}
                value={titleProps.value}
                onChange={titleUpdate}
                onInputChange={titleUpdate}
              />
          <TextareaAutosize {...description} />
        </div>

        <div>
          <h4>データ編集者 引継ぎコメント</h4>
          <TextareaAutosize {...comment} />
        </div>
      </div>



    </div>
   );
}
