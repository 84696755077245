import { React, useState, useEffect, useCallback } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { Button, CircularProgress, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';


import './Publication.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";
import FilesListDialog from "./FilesListDialog";

export default function PublicationEditData(props) {
  const { postPublicationFile, loadPublicationFiles } = useMyData();

  const [ id, setId ] = useState(props.id);

  const [files, setFiles] = useState([]); // Files
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  let ffiles = [];

  useEffect(()=>{
    setId(props.id);
  },[props.id]);

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }


  useEffect(()=>{
    loadPublicationFiles(id,setInitFiles);
  },[]);


  const setUploadedFile = (file) => {
    loadPublicationFiles(id,setInitFiles);
  }

  const uploadFile = (file) => {
    postPublicationFile(id,file,setUploadedFile);
  }


  const openFilesDialog = (e) => {
    setFilesDialogOpen(true);
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }

  const updateFile = (e) => {
    loadPublicationFiles(id,setInitFiles);
  }

  return (
    <div className="mainArea dekigotoFile">
      <p>下記のボタンから資料をアップロードしてください。</p>
      
      <FileDropzone upload={uploadFile} />

      {files.length > 0 && 
        <>
          <div className="uploadText">下記のボタンからアップロードした資料を確認できます。</div>
          <Button onClick={openFilesDialog}>付属データ <span className="num">{files.length}</span></Button>
        </>
      }


      <FilesListDialog open={filesDialogOpen} onClose={closeFilesDialog} updateFile={updateFile} files={files} title={props.publication.title} id={id}/>

    </div>
   );
}
