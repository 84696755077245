import { React, useEffect, useState, useRef } from 'react';
import { useMyData } from './MyProvider';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';

import './FileData.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
//import FileDownloadIcon from './Icons/FileDownloadIcon';
import FileDownloadIcon from './Icons/FileDownloadIcon';
import KeepPinIcon from './Icons/KeepPinIcon';


export default function FileDataViewKeep(props) {
  const { loadJSON } = useMyData();

  const [id, setId] = useState(props.data.id);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [filename, setFilename] = useState('sample.svg');

  const ref = useRef(null)


  useEffect(() => {
    if (props.data.filename.match(/\.xlsx?$/i)) {
      setFilename('icon_excel.png');
    }
    if (props.data.filename.match(/\.docx?$/i)) {
      setFilename('icon_word.png');
    }
    if (props.data.filename.match(/\.pdf?$/i)) {
      setFilename('icon_pdf.png');
    }
    if (props.data.mime_type.match(/^image\//i)) {
      setFilename('');
    }
  }, []);

  const loadedImg = () => {
    setImgWidth(ref.current.clientWidth);
    setImgHeight(ref.current.clientHeight);
  }

  const removeImage = (e) => {
    props.removeImage(e);
  }

  const token = loadJSON("token");

  return (
    <div className="fileDataView">
      <div className="thumbnail">
        <div>

          {filename !== '' ?
            <img ref={ref} src={`${process.env.PUBLIC_URL}/img/${filename}`} onLoad={loadedImg} />
            :
            <img ref={ref} src={`${process.env.REACT_APP_API_ENDPOINT}file/${id}/${token}`} onLoad={loadedImg} />
          }
          {props.data.nodownload == 1 &&
          <div className="overflow" style={{ width: imgWidth, height: imgHeight }}></div>
          }
        </div>
      </div>


      <div className="data">
        <div className='buttons'>
          <Button
            variant="outlined"
            color="outlinedbutton"
            onClick={removeImage}
            value={id}
            size="small"
          >
            リストから削除
          </Button>
        </div>
      </div>

    </div>
  )
}