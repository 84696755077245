import { React, useState, useEffect, useCallback, useRef } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useOutsideHandle } from "./hooks";
import { CircularProgress, DialogContent, TextField, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {arrayMoveImmutable} from "array-move";


import './Company.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";
import CompanyEditMain2Data from "./CompanyEditMain2Data";
import { Container, Draggable } from "react-smooth-dnd";
import ThreeBarIcon from "./Icons/ThreeBarIcon";
import TrashIcon from "./Icons/TrashIcon";
import InvisibleIcon from "./Icons/InvisibleIcon";
import VisibleIcon from "./Icons/VisibleIcon";
import CompanyPreviewDialog from "./CompanyPreviewDialog";

export default function CompanyEditHistory(props) {
  const { companyHistoriesUpdate, loadCompanyHistories } = useMyData();

  const wrapperRef = useRef(null);

  const [ id, setId ] = useState(props.id);
  const [loading,setLoading] = useState(false);
  const [ret, setRet] = useState(true); // 登録時の戻り値

  const [selected,setSelected] = useState(0); // 選択されているもの
  const [editid,setEditid] = useState(0); // 編集用に表示されているもの
  const [historyProps,,setHistory] = useInput(""); // 沿革
  const [historyYearProps,,setHistoryYear] = useInput("0000","number"); // 沿革年
  const [historyMonthProps,,setHistoryMonth] = useInput("00","number"); // 沿革月
  const [histories,setHistories] = useState([]);

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  useEffect(()=>{
    loadCompanyHistories(id,setHistories);
  },[]);

  const outsideclick = () => {
    setSelected(0);
  }
  useOutsideHandle(wrapperRef , outsideclick);

  const add = () => {
    const data = {
      content : historyProps.value,
      year   : Number(historyYearProps.value),
      month : Number(historyMonthProps.value),
      visible : true,
    };

    let datas = [...histories];

    if ( editid > 0 ) {
      datas[editid - 1] = data;
    } else {
      datas.push(data);
    }

    //let result = datas.sort(function(a, b) {
    //  return (a.year * 100 + a.month < b.year * 100 + b.month) ? -1 : 1;
    //});
    let result = datas;

    setHistories(result);
    setEditid(0);
    setSelected(0);
  }

  const select = (e) => {
    setSelected(e.currentTarget.dataset['i']);
  }
  const editmode = () => {
    const data = histories[selected - 1];
    setHistory(data.content);
    setHistoryYear(data.year);
    setHistoryMonth(data.month);
    setEditid(selected);
  }
  const del = () => {
    const result = histories.filter(function( data,i ) {
      return selected - 1 == i ? false : true;
    });
    setHistories(result);

    setEditid(0);
    setSelected(0);
  }

  useEffect(()=>{
    if ( histories.length > 0 ) {
      //companyHistoriesUpdate({
      //  v4: id,
      //  histories: histories
      //},setRet);
    }
  },[histories])

  const preview = () => {
    console.log("OPEN");
    setPreviewDialogOpen(true);
  }

  const closePreviewDialog = () => {
    setPreviewDialogOpen(false);
  }

  const onDrop = (dropResult) => {
    const { removedIndex, addedIndex } = dropResult;
    setHistories(
      arrayMoveImmutable(histories, removedIndex, addedIndex)
    );
  }

  const toggleVisible = (e) => {
    const data = histories[e.currentTarget.dataset['i'] - 1];
    data.visible = !data.visible;
    histories[e.target.dataset['i'] - 1] = data;

    setHistories( [...histories] );

  }

  const deleteItem = (e) => {
    const result = histories.filter(function( data,i ) {
      return e.currentTarget.dataset['i'] - 1 == i ? false : true;
    });
    setHistories(result);
    if ( result.length == 0 ) {
      //companyHistoriesUpdate({
      //  v4: id,
      //  histories: result
      //},setRet);
    }
  }

  const updateData = () => {
    companyHistoriesUpdate({
      v4: id,
      histories: histories
    },setRet);
  }

  return (
    <div className="mainArea companyHistory">
      <p>年月・内容</p>
      <input className="company" type="number" min="0" max="2500" {...historyYearProps} /> 年　
      <input className="company" type="number" min="0" max="12" {...historyMonthProps} /> 月　

      <div className="content">
        {historyProps.value.length}/100 <br />
        <textarea multiline="true" rows={2} className="company input" {...historyProps} /> 　
      </div>

      <button className="preview" onClick={preview}>コンテンツプレビュー</button>

      <div className="companyEdit" ref={wrapperRef}>
        <div className="companyEditButtons">
          <button className="add" onClick={add}>{editid ? '更新' : '追加'} <ArrowDropDownIcon /></button>
          <button className="edit" disabled={selected == 0} onClick={editmode}>編集 <ArrowDropUpIcon /></button>
        </div>

        <div className="companyHistoryList">
          <div className="tableHead">
            <div></div>
            <div>年</div>
            <div>月</div>
            <div>内容</div>
          </div>

          <DialogContent>
            <div className="selects">
              <div className="items">
                <Container 
                  onDrop={onDrop}
                  lockAxis="y"
                  dragHandleSelector=".dragHandle"
                  dragClass="drag"
                >
                  {histories.map((data,i) => 
                    <Draggable 
                      key={i + 1} 
                      className={i+1==selected ? 'itemArea selected' : 'itemArea'}
                      data-i={i+1}
                      onClick={select}
                    >
                      <div className="dragHandle">
                        <ThreeBarIcon />
                      </div>
                      <div>{data.year}</div>
                      <div>{data.month}</div>
                      <div>{data.content}</div>
                      <div>
                        <button onClick={toggleVisible} data-i={i+1} data-name={data.name}> {data.visible ? <VisibleIcon /> : <InvisibleIcon />}</button>
                        <button onClick={deleteItem} data-i={i+1} data-name={data.name}><TrashIcon /></button>
                      </div>
                    </Draggable>
                  )}
                </Container>
              </div>
            </div>
          </DialogContent>
        
        </div>

        <div className="buttons">
        <LoadingButton loading={loading} onClick={updateData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
          登録
        </LoadingButton>
      </div>

      </div>

      <CompanyPreviewDialog 
        open={previewDialogOpen}
        onClose={closePreviewDialog}
        year={historyYearProps.value}
        month={historyMonthProps.value}
        content={historyProps.value} />
    </div>
   );
}
