import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import './Company.scss';
import { Badge } from "@mui/material";
import FileDataView from "./FileDataView";
import FilesDialog from "./FilesDialog";
import { useLocation } from "react-router-dom";
import { useInput } from "./hooks";

export default function CompanyEditPreview(props) {
  const { loadCompanyFiles, companyCategories, loadCompanyMetas, loadCompanyHistories } = useMyData();

  const [id, setId] = useState(props.id);
  const [files, setFiles] = useState([]); // Files
  const [logofile, setLogoFile] = useState([]); // LogoFiles
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  const [companyCategory,setCompanyCategory] = useState([]); // 事業分類一覧
  const [companies,setCompanies] = useState([]); // 事業分類用に会社一覧取得

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [capitalsList,setCapitalsList] = useState(false);
  const [salesList,setSalesList] = useState(false);
  const [stakesList,setStakesList] = useState(false);
  const [employeesList,setEmployeesList] = useState(false);
  const [storesList,setStoresList] = useState(false);
  const [metaData,setMetaData] = useState([]);
  const [histories,setHistories] = useState([]);

  let ffiles = [];

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const print = query.get('print')
  
  useEffect(() => {
    if(print){
      setTimeout(() => {
        window.print();
        window.close();
      }, 1000)
    }
  },[]);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useEffect(()=>{
    loadCompanyMetas(id,setMetaData);
  },[]);

  useEffect(()=>{
    loadCompanyHistories(id,setHistories);
  },[]);


  useEffect(()=>{
    if(typeof props.company.foundation_date !== 'undefined' ){
      const dates = props.company.foundation_date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);
    }
  
  },[props.company]);


  useEffect(() => {
    loadCompanyFiles(id, setInitFiles);
  }, []);

  useEffect(()=>{
    setCompanies(props.companies);
  },[props.companies]);

  useEffect(()=>{
    if(companies.length > 0){
      setCompanyCategory(companyCategories(companies));
    }
  },[companies]);


  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }

  useEffect(() => {
    let logoFile = [];
    files.forEach(ele => {
      if(ele.logo==1) {
        logoFile.push(ele);
      }
    });
    setLogoFile(logoFile);
  },[files]);

  const printPage = () => {
    window.print();
  }

  const openFilesDialog = (e) => {
    setFilesDialogOpen(true);
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }

  const zipformat = (zip) => {
    return zip ? zip.replace(/^(\d{3})(\d{4})$/, '$1-$2') : '';
  }

  const toggleCapitalsList = () => {
    setCapitalsList(!capitalsList);
  }
  const toggleSalesList = () => {
    setSalesList(!salesList);
  }
  const toggleStakesList = () => {
    setStakesList(!stakesList);
  }
  const toggleEmployeesList = () => {
    setEmployeesList(!employeesList);
  }
  const toggleStoresList = () => {
    setStoresList(!storesList);
  }

  return (
    <div className="mainArea preview">
      <div className="buttonArea">
        {files.length > 0 && 
        <Badge badgeContent={files.length} color="accent">
          <button onClick={openFilesDialog}>付属データ</button>
        </Badge>
        }
        <Badge color="accent">
          <button onClick={printPage}>印刷</button>
        </Badge>
      </div>

      <div className="titleArea">
      <div className="title">{props.company.name}</div>
      <div className="title">{props.company.name_en}</div>
      </div>

      <div className="dataArea">
        <input type="checkbox" id="dataAreaCheck" />
        <dl>
        <dt>掲載場所</dt>
          <dd>
            {props.media.map((data,i) => <Fragment key={i}>{data.checked ? <span>{data.name}</span> : ""}</Fragment> )}
          </dd>
          <dt>分類</dt>
          <dd>
            {companyCategory.map((data,i) => 
            <Fragment key={i}>
              {props.company.category == data.category && <>{data.category_name}</> }
            </Fragment>
            )}
          </dd>
          <dt>代表取締役</dt>
          <dd>{props.company.ceo}</dd>

          <dt>設立年月日</dt>
          <dd>{yearProps.value}年 {monthProps.value > 0 && monthProps.value + '月'} {dayProps.value > 0 && dayProps.value + '日'}</dd>

          <dt>資本金
            <button className={capitalsList ? "active" : ""} onClick={toggleCapitalsList}>推移</button>
          </dt>
          <dd>
            {props.company.capitals && props.company.capitals.map((data,i) => 
              (capitalsList || props.company.capitals.length == i + 1) && <Fragment key={i}>{data.amount} {data.unit} ({data.year}年 {data. month}月末)<br /></Fragment>
            )}
          </dd>

          <dt>
            {props.company.sales_type==1 && 'グループ売上'}
            {props.company.sales_type==2 && 'チェーン全店売上'}
            {props.company.sales_type==3 && '営業収益'}
            {props.company.sales_type==4 && '売上高'}
            <button className={salesList ? "active" : ""} onClick={toggleSalesList}>推移</button>
          </dt>
          <dd>
            {props.company.sales && props.company.sales.map((data,i) => 
              (salesList || props.company.sales.length == i + 1) && <Fragment key={i}>{data.amount} {data.unit} ({data.year}年 {data. month}月末)<br /></Fragment>
            )}
          </dd>

          <dt>出資比率
            <button className={stakesList ? "active" : ""} onClick={toggleStakesList}>推移</button>
          </dt>
          <dd>
            {props.company.stakes && props.company.stakes.map((data,i) => 
              (stakesList || props.company.stakes.length == i + 1) && <Fragment key={i}>{data.amount}% ({data.year}年 {data. month}月末)<br /></Fragment>
            )}
          </dd>

          <dt>従業員数
            <button className={employeesList ? "active" : ""} onClick={toggleEmployeesList}>推移</button>
          </dt>
          <dd>
            {props.company.employees && props.company.employees.map((data,i) => 
              (employeesList || props.company.employees.length == i + 1) && <Fragment key={i}>{data.amount} ({data.year}年 {data. month}月末)<br /></Fragment> 
            )}
          </dd>

          <dt>店舗数
            <button className={storesList ? "active" : ""} onClick={toggleStoresList}>推移</button>
          </dt>
          <dd>
            {props.company.stores && props.company.stores.map((data,i) => 
              (storesList || props.company.stores.length == i + 1) && <Fragment key={i}>{data.amount} ({data.year}年 {data. month}月末)<br /></Fragment> 
            )}
          </dd>

          <dt>所在地</dt>
          <dd>〒{zipformat(props.company.zip)} {props.company.pref} {props.company.addr1} {props.company.addr2}</dd>

          <dt>最終更新日</dt>
          <dd>
            {props.company.mdatetime.replace(/-/g,'/').replace(/\s.+$/,'')}
          </dd>
          <dt>更新作業者</dt>
          <dd>{props.company.user_post} {props.company.user_name}</dd>
          <dt>更新指示者</dt>
          <dd>{props.company.commander}</dd>
          <dt>コメント</dt>
          <dd className="multiline">{props.company.comment}</dd>
        </dl>
        <label htmlFor="dataAreaCheck"></label>
      </div>

      <div className="detailArea">
        <div className="imageArea">
          {logofile.map((data,i) => <FileDataView key={data.id} data={data} /> )}
        </div>

        <div className="textArea">
          {metaData.map((data,i) => 
            <Fragment
              key={i + 1} 
            >
              <h3>{data.title}</h3>
              <div>{data.description}</div>
            </Fragment>
          )}

          <h3>沿革</h3>
          {histories.map((data,i) => 
            <Fragment
              key={i + 1} 
            >
              <div className="history">
                <span>{data.year}年</span>
                <span>{data.month}月</span>
                <span>{data.content}</span>
              </div>
            </Fragment>
          )}

          {props.company.description}
        </div>

      </div>

      <FilesDialog open={filesDialogOpen} onClose={closeFilesDialog} files={files}  />

    </div>
  );
}
