import { React } from "react";

export default function PencilIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.076" height="17.076" viewBox="0 0 17.076 17.076">
      <defs>
        <clipPath id="clip-path">
          <rect id="長方形_472" data-name="長方形 472" width="17.076" height="17.076" transform="translate(0 0)" fill="none" />
        </clipPath>
      </defs>
      <g id="pen" transform="translate(0 0)">
        <g id="グループ_10925" data-name="グループ 10925" clipPath="url(#clip-path)">
          <path id="パス_10088" data-name="パス 10088" d="M.75,17.077a.751.751,0,0,1-.724-.945l1.165-4.347a.744.744,0,0,1,.194-.336L12.615.22a.749.749,0,0,1,1.06,0L16.857,3.4a.749.749,0,0,1,0,1.06L14.981,6.338,5.628,15.692a.75.75,0,0,1-.336.194L.945,17.051a.769.769,0,0,1-.195.026m1.838-4.71-.777,2.9,2.9-.777L15.266,3.932,13.145,1.811ZM14.451,5.808h0Z" fill="#2e3d49" />
        </g>
      </g>
    </svg>
  );
}
