import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, Backdrop, TextField, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";

import './Dekigoto.scss';
import { MyDatePicker } from "./MyDatePicker";
import { MySelectYear } from "./MySelectYear";
import { MySelectMonth } from "./MySelectMonth";
import { MyButton } from "./MyButton";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MySearch } from "./MySearch";
import { MySelectListType } from "./MySelectListType";
import { MySelectCount } from "./MySelectCount";
import { MySelectSort } from "./MySelectSort";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DekigotoListList from "./DekigotoListList";
import DekigotoListTile from "./DekigotoListTile";
import DekigotoListImage from "./DekigotoListImage";
import MediaSelectDialogBig from "./MediaSelectDialogBig";
import CompanySelectDialogBig from "./CompanySelectDialogBig";

export default function DekigotoList() {
  //let {page} = useParams() || 1;

  const { param, setParam, setMenu, getDekigotoList, getMedias, getCompanies } = useMyData();
  const navigate = useNavigate();

  const [list, setList] = useState({ list: [] });
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  

  const [page, setPage] = useState( param.page ||  1 );

  const [wordProps,, setWord] = useInput(param.word || ""); // 検索ワード
  const [media,setMedia] = useState(param.media || []); // 掲載場所
  const [company,setCompany] = useState(param.company || []); // 関連会社
  const [syearProps,, setSYear] = useInput(param.syear || "","number"); // 年
  const [smonthProps,, setSMonth] = useInput(param.smonth || "","number"); // 月
  const [eyearProps,, setEYear] = useInput(param.eyear || "","number"); // 年
  const [emonthProps,, setEMonth] = useInput(param.emonth || "","number"); // 月
  const [dateS,setDateS] = useState(param.dateS || null); // 更新日S
  const [dateE,setDateE] = useState(param.dateE || null); // 更新日E
  const [checked, setChecked] = useState(param.file ? true : false); // 付属データあり

  const [wordHistory, setWordHistory] = useState(param.wordHistory || []); // 検索ワード履歴
  
  const [selectType,setSelectType] = useState(param.type || 'list');
  const [selectCount,setSelectCount] = useState(param.count || 50);
  const [selectSort,setSelectSort] = useState(param.sort || 'date desc');

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);


  useEffect(()=>{
    if(param.media && param.media.length > 0){
    } else {
      getMedias(setMedia,0);
    }
  },[]);

  useEffect(()=>{
    if(param.company && param.company.length > 0 ){
    } else {
      getCompanies(setCompany,0);
    }
  },[]);


  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if(e){
      setMedia(e);
    }
  }

  const openCompanyDialog = (e) => {
    setCompanyDialogOpen(true);
  }
  const closeCompanyDialog = (e) => {
    setCompanyDialogOpen(false);
    if(e){
      setCompany(e);
    }
  }



  useEffect(() => {
    setMenu('dekigotolist');
  }, []);

  useEffect(() => {
    searchHandlerWithPage(page);
  }, []);

  useEffect(() => {
    searchHandlerWithPage(1);
  }, [ selectType, selectCount, selectSort]);

  const changePage = (e) => {
    searchHandlerWithPage(e.currentTarget.dataset.page);
  }

  const jumpPage = (e) => {
    //navigate('/dekigoto/data/' + e.currentTarget.dataset.id);
    navigate('/dekigoto/detail/' + e.currentTarget.dataset.id);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  }

  const setSearchWord = (e) => {
    setWord(e.currentTarget.dataset.word);
  }

  const updateHistory = () => {
    // 重複排除
    const set = new Set([
      wordProps.value,
      ...wordHistory.filter((item) => {
        if(
          item == wordProps.value
        ) {
          return false;
        }
        return item;
      })
    ]);

    setWordHistory([...set].slice(0, 5));
  }
  useEffect(() => {
    searchHandlerWithPage(1);
  }, [wordHistory]);

  const searchHandler = () => {
    updateHistory();
  }
  const searchHandlerWithPage = (p) => {
    setLoading(true);
    setList({
      ...list,
      current_page: p,
    })
    const searchParam = {
      word : wordProps.value, // 検索ワード
      wordHistory : wordHistory, // 検索ワード履歴
      media : media, // 掲載場所
      company : company, // 関連会社
      syear : syearProps.value, // 年
      smonth : smonthProps.value, // 月
      eyear : eyearProps.value, // 年
      emonth : emonthProps.value, // 月
      dateS : dateS, // 更新日S
      dateE : dateE, // 更新日E
      file : checked,  // 付属データあり
      type : selectType,
      count : selectCount,
      sort : selectSort,
    };
    getDekigotoList({
      page: p ,
      ...searchParam
    }, setList, setLoading);
  }
  const resetHandler = () => {
    setWord("");
    setSYear("");
    setSMonth("");
    setEYear("");
    setEMonth("");
    setDateS(null);
    setDateE(null);
    setChecked(false);

    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    );
    setCompany(
       company.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    )

  }
  
  const makePages = () => {
    const pagecount = 9;
    let localPages = [];
    let startP = list.current_page - 1 - Math.floor(pagecount / 2);
    if (startP < 0) {
      startP = 0;
    }
    let endP = startP + pagecount - 1;
    if(endP >= Number(list.last_page)) {
      endP = list.last_page - 1;
      startP = endP - pagecount + 1;
      if (startP < 0) {
        startP = 0;
      }
    }
    for (let p=startP; p <= endP; p++) {
      localPages.push(p);
    }
    setPages(localPages);
    setPrev(list.current_page > 1 ? true : false);
    setNext(list.current_page < list.last_page ? true : false);
  }
  useEffect(()=>{
    makePages();
  },[list]);

  
  //list.last_page 
  //value + 1 == list.current_page

  return (
    <div className="dekigoto">
      <div className="tableBase nopadding bottomSpace">
        <dl className="searchConditions">
          <dt>キーワード</dt>
          <dd>
            <MySearch {...wordProps} />
            <MyButton onClick={searchHandler}>検索</MyButton>
            <br />
            {wordHistory.map((data,i) => <span key={i} className="history" data-word={data} onClick={setSearchWord}>{data}</span>)}
          </dd>
          <dt>
            掲載場所
            <MyButton className="select" onClick={openMediaDialog} endIcon={<ArrowRightIcon sx={{ color: "#F88B5E" }} />}>選択</MyButton>
          </dt>
          <dd>
            {media.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
          </dd>
          <dt>
            関連企業
            <MyButton className="select" onClick={openCompanyDialog} endIcon={<ArrowRightIcon sx={{ color: "#F88B5E" }} />}>選択</MyButton>
          </dt>
          <dd>
            {company.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
          </dd>
          <dt>できごと年月日</dt>
          <dd>
            <Grid container direction="row" alignItems="center">
              <MySelectYear {...syearProps} />　年　
              <MySelectMonth {...smonthProps} />　月　
              〜　
              <MySelectYear {...eyearProps} />　年　
              <MySelectMonth {...emonthProps} />　月　
              　
              <FormControlLabel control={
                <Checkbox 
                  checked={checked}
                  onChange={handleChange}
                  sx={{
                    color: "#4D4F5C",
                    '&.Mui-checked': {
                      color: "#26C1C9",
                    },
                  }} />
                } label="付属データあり" />
            </Grid>
          </dd>
          <dt>更新日</dt>
          <dd>
            <Grid container direction="row" alignItems="center">
              <MyDatePicker
                value={dateS}
                onChange={(newValue) => {
                  setDateS(newValue)
                }}
              />
              　〜　
              <MyDatePicker
                value={dateE}
                onChange={(newValue) => {
                  setDateE(newValue)
                }}
              />
            </Grid>

            <div className="buttonPos">
              <MyButton onClick={resetHandler} className="reset">全項目消去</MyButton>
              <MyButton onClick={searchHandler}>検索</MyButton>
            </div>

          </dd>
        </dl>
      </div>

      <div className="tableBase">
        <div className="pager">
          {list.last_page > 0 && list.list.length > 0 &&
            <>
              <ul>
                {prev && <li className="withIcon"><span onClick={changePage} data-page={list.current_page - 1}><KeyboardDoubleArrowLeftIcon /></span></li>}
                {pages.map((value) => <li key={list.current_page + '-' + value}>
                  {value + 1 == list.current_page
                    ? <span className="current" onClick={changePage} data-page={value + 1}>{value + 1}</span>
                    : <span to="#" onClick={changePage} data-page={value + 1}>{value + 1}</span>}
                </li>)}
                {next && <li className="withIcon"><span onClick={changePage} data-page={list.current_page + 1}><KeyboardDoubleArrowRightIcon /></span></li>}
              </ul>
            </>
          }
          
          <div className="selects">
            <MySelectListType value={selectType} onChange={(e)=>{setSelectType(e.target.value)}} />
            <MySelectCount value={selectCount} onChange={(e)=>{setSelectCount(e.target.value)}} />
            <MySelectSort value={selectSort} onChange={(e)=>{setSelectSort(e.target.value)}} />
          </div>

        </div>
        {loading ? 
        <div className="loading">
          <CircularProgress />
        </div>
        :
        <>
          {list.list.length > 0 ?
           <>
           { selectType == 'list' && <DekigotoListList list={list} /> }
           { selectType == 'tile' && <DekigotoListTile list={list} /> }
           { selectType == 'image' && <DekigotoListImage list={list} /> }
           </>
          :
          <div>
            条件にマッチする「できごと」は、ありません。<br /><br />
          </div>
          }
        </>
        }
      </div>

      <MediaSelectDialogBig open={mediaDialogOpen} onClose={closeMediaDialog} values={media}  />
      <CompanySelectDialogBig open={companyDialogOpen} onClose={closeCompanyDialog} values={company}  />

    </div>
  );
}
