import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

import './Dekigoto.scss';
import DekigotoEditMain from "./DekigotoEditMain";
import DekigotoEditData from "./DekigotoEditData";
import DekigotoEditPreview from "./DekigotoEditPreview";
import { Button } from "@mui/material";
import { MyButton } from "./MyButton";
import { MyButtonBack } from "./MyButtonBack";
import { MyButtonEdit } from "./MyButtonEdit";

export default function DekigotoDetail() {
  let {id} = useParams();
  const navigate = useNavigate();

  const { data, setMenu,getMedias,getCompanies,getDekigoto } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル

  const [media,setMedia] = useState([]); // 掲載場所
  const [company,setCompany] = useState([]); // 関連会社

  const [dekigoto, setDekigoto] = useState({}); // 出来事データ


  const [tab,setTab] = useState(0); // TAB

  useEffect(()=>{
    getMedias(setMedia,id);
  },[id]);

  useEffect(()=>{
    getCompanies(setCompany,id);
  },[id]);

  useEffect(()=>{
    getDekigoto(setDekigoto,id);
  },[id]);

  useEffect(()=>{
    if(typeof dekigoto.date !== 'undefined' ){
      const dates = dekigoto.date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(dekigoto.title);
    }
  
  },[dekigoto]);


  useEffect(()=>{
    setMenu('dekigotolist');
  },[]);

  const back = () => {
    navigate('/dekigoto/list/');
  }
  const edit = () => {
    navigate('/dekigoto/data/' + id);
  }

  return (
    <div className="dekigoto dekigotodetail">
      <div className="buttons">
        <MyButtonBack onClick={back}>
          検索結果に戻る
        </MyButtonBack>
        {data.role>=3 &&
        <MyButtonEdit onClick={edit}>
          この記事を編集
        </MyButtonEdit>
        }
      </div>
      <div className="detail">
        {dekigoto.date && <DekigotoEditPreview id={id} dekigoto={dekigoto} company={company} media={media} />}
      </div>
    </div>
 );
}
