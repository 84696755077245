import {React, useEffect, useState} from 'react';
import { useMyData } from './MyProvider';
import { useCheckbox, useInput } from "./hooks";
import { LoadingButton } from '@mui/lab';
import { Checkbox, CircularProgress, FormControlLabel, TextareaAutosize } from '@mui/material';

import './FileData.scss';
import ConfirmDialog from './ConfirmDialog';

export default function FileData(props) {
  const { loadJSON, fileUpdate, fileDelete } = useMyData();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [ id, setId ] = useState(props.data.id);
  const [ mode, setMode ] = useState(props.mode || '');
  const [ mainimg,, setMainimg ] = useCheckbox(props.data.mainimg==1 ? ["1"] : []);
  const [ logo,, setLogo ] = useCheckbox(props.data.logo==1 ? ["1"] : []);
  const [ nodownload,, setNodownload ] = useCheckbox(props.data.nodownload==1 ? ["1"] : []);
  const [ description,, setDescription ] = useInput(props.data.description || '');
  const [ comment,, setComment ] = useInput(props.data.comment || '');
  

  const [loading,setLoading] = useState(false);
  const [ret, setRet] = useState({}); // 登録時の戻り値

  let filename = 'sample.svg';
  if(props.data.filename.match(/\.xlsx?$/i)) {
    filename = 'icon_excel.png';
  }
  if(props.data.filename.match(/\.docx?$/i)) {
    filename = 'icon_word.png';
  }
  if(props.data.filename.match(/\.pdf?$/i)) {
    filename = 'icon_pdf.png';
  }
  if(props.data.mime_type.match(/^image\//i)) {
    filename = '';
  }



  const updateData = () => {
    setLoading(true);
    fileUpdate(id, {
      mainimg: mainimg.val.includes("1") ? "1" : "0",
      logo: logo.val.includes("1") ? "1" : "0",
      nodownload: nodownload.val.includes("1") ? "1" : "0",
      description: description.value,
      comment: comment.value,
    },setRet);
  }

  const deleteData = () => {
    setLoading(true);
    fileDelete(id, setRet);
  }

  useEffect(()=>{

    if(ret.status==='OK'){
      setLoading(false);
    }
    if(ret.status==='DELETED'){
      setLoading(false);
    }
  },[ret]);

  const closeDialog = (e) => {
    setConfirmDialogOpen(false);
  }
  const openDialog = (e) => {
    setConfirmDialogOpen(true);
  }
  const yesDialog = (e) => {
    setConfirmDialogOpen(false);
    deleteData();
  }


  const token = loadJSON("token");

  if(ret.status==='DELETED'){
    return (<></>);
  }


  return (
    <div className="fileData">
      <ConfirmDialog open={confirmDialogOpen} onClose={closeDialog} onYes={yesDialog} title="確認" msg="この資料を削除します" />
      <div className="thumbnail">
        <div>
          <a href={`${process.env.REACT_APP_API_ENDPOINT}file/${id}/${token}`}  download={props.data.filename} >
          {filename !== '' ?
          <img src={`${process.env.PUBLIC_URL}/img/${filename}`} />
          :
          <img src={`${process.env.REACT_APP_API_ENDPOINT}file/${id}/${token}`} />
          }
          </a>
          <div className='filename'>
            <div>{props.data.filename}</div>
            <div>
              {props.data.filesize > 1000000 ? 
                <>{Math.round(props.data.filesize / 1000000).toLocaleString()}MB</>
              :
                <>{Math.round(props.data.filesize / 1000).toLocaleString()}KB</>
              }
            </div>
          </div>
        </div>
        <div className='text'>
          <FormControlLabel control={<Checkbox {...mainimg} checked={mainimg.val.includes("1")} value="1" />} label="このデータをメイン画像とする" />
          {mode=='company' && <FormControlLabel control={<Checkbox {...logo} checked={logo.val.includes("1")} value="1" />} label="ロゴデータ" />}
        </div>
      </div>

      <div className="nodownload">
        <img src={`${process.env.PUBLIC_URL}/img/nodownload.svg`} />
        <div className='text'>
          <FormControlLabel control={<Checkbox {...nodownload} checked={nodownload.val.includes("1")} value="1" />} label="ダウンロード不可" />
          <div>
            ※チェック時には、左記のように<br />
            ダウンロード不可マークが画像上に表示されます。
          </div>
        </div>
      </div>
      
      <div>
        <h4>コメント</h4>
        <TextareaAutosize {...description} />
      </div>

      <div>
        <h4>データ編集者 引継ぎコメント</h4>
        <TextareaAutosize {...comment} />
      </div>

      <div className="buttons">
        <LoadingButton loading={loading} onClick={openDialog} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
          削除
        </LoadingButton>

        <LoadingButton loading={loading} onClick={updateData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
          更新
        </LoadingButton>
      </div>

    </div>
  )
}