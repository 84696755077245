import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import './Dekigoto.scss';
import { Badge } from "@mui/material";
import FileDataView from "./FileDataView";
import FilesDialog from "./FilesDialog";
import { useLocation } from "react-router-dom";

export default function DekigotoEditPreview(props) {
  const { loadDekigotoFiles } = useMyData();

  const [id, setId] = useState(props.id);
  const [files, setFiles] = useState([]); // Files
  const [mainfile, setMainFile] = useState([]); // MainFiles
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  let ffiles = [];

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const print = query.get('print')
  
  useEffect(() => {
    if(print){
      setTimeout(() => {
        window.print();
        window.close();
      }, 1000)
    }

  },[]);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useEffect(() => {
    loadDekigotoFiles(id, setInitFiles);
  }, []);

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }

  useEffect(() => {
    let mainFile = [];
    files.forEach(ele => {
      if(ele.mainimg==1) {
        mainFile.push(ele);
      }
    });
    setMainFile(mainFile);
  },[files]);

  const printPage = () => {
    window.print();
  }

  const openFilesDialog = (e) => {
    setFilesDialogOpen(true);
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }


  return (
    <div className="mainArea preview">
      <div className="buttonArea">
        {files.length > 0 && 
        <Badge badgeContent={files.length} color="accent">
          <button onClick={openFilesDialog}>付属データ</button>
        </Badge>
        }
        <Badge color="accent">
          <button onClick={printPage}>印刷</button>
        </Badge>
      </div>

      <div className="titleArea">
        <div className="date">{props.dekigoto.date.replace(/-/g, '/')}</div>
        <div className="title">{props.dekigoto.title}</div>
      </div>

      <div className="dataArea">
        <input type="checkbox" id="dataAreaCheck" />
        <dl>
          <dt>掲載場所</dt>
          <dd>
            {props.media.map((data,i) => <Fragment key={i}>{data.checked ? <span>{data.name}</span> : ""}</Fragment> )}
          </dd>
          <dt>関連会社</dt>
          <dd>
            {props.company.map((data,i) => <Fragment key={i}>{data.checked ? <span>{data.name}</span> : ""}</Fragment> )}
          </dd>
          <dt>最終更新日</dt>
          <dd>
            {props.dekigoto.mdatetime.replace(/-/g,'/').replace(/\s.+$/,'')}
          </dd>
          <dt>更新作業者</dt>
          <dd>{props.dekigoto.user_post} {props.dekigoto.user_name}</dd>
          <dt>更新指示者</dt>
          <dd>{props.dekigoto.commander}</dd>
          <dt>コメント</dt>
          <dd>{props.dekigoto.comment}</dd>
        </dl>
        <label htmlFor="dataAreaCheck"></label>
      </div>

      <div className="detailArea">
        <div className="textArea">
          {props.dekigoto.description}
        </div>

        <div className="imageArea">
          {mainfile.map((data,i) => <FileDataView key={data.id} data={data} /> )}
        </div>
      </div>

      <FilesDialog open={filesDialogOpen} onClose={closeFilesDialog} files={files}  />

    </div>
  );
}
