import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import './Header.scss';
import FilesDialog from "./FilesDialog";

export default function Header(props) {
  const { data,signOut,downloadlist,setDownloadlist } = useMyData();

  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  const openFilesDialog = (e) => {
    if(downloadlist.length > 0) {
      setFilesDialogOpen(true);
    }
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }

  const deleteItem = (e) => {
    setDownloadlist(downloadlist.filter((item) => {
      return item.id !== e.target.value
    }));
  }

  // ログイン
  const signout = (e) => {
    signOut()
  }

  return (
    <div className="header">
      <h1>セブン&アイ　データベース</h1>
      <div className="controls">
        {data.role>=2 &&
        <div className="downloadList" onClick={openFilesDialog} >ダウンロードリスト{downloadlist.length > 0 ? <span>{downloadlist.length}</span> : ""}</div>
        }

        <span className="user">
          ID:{data.user}
          {data.role==1 && "（閲覧者）"}
          {data.role==2 && "（閲覧&ダウンロード者）"}
          {data.role==3 && "（編集者）"}
          {data.role==4 && "（管理者）"}
        </span>
        <div className="signoutButton" onClick={signout}>ログアウト</div>
      </div>
      <FilesDialog open={filesDialogOpen} onClose={closeFilesDialog} files={downloadlist} target="download" deleteItem={deleteItem} />
    </div>
 );
}
