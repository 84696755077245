import { React, useState, useEffect } from "react";
import { useMyData } from './MyProvider';

import './Company.scss';
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

export default function CompanyExport() {
  const { setMenu, companyExport } = useMyData();

  const [loading, setLoading] = useState(false);
  const [ret, setRet] = useState(true); // 登録時の戻り値

  useEffect(() => {
    setMenu('companylist');
  }, []);

  const exportData = () => {
    setLoading(true);
    companyExport(setRet);
  }

  useEffect(()=>{
    setLoading(false);
    if(ret.status==='OK'){
    }
  },[ret]);


  return (
    <div className="company">
      <div className="mainArea">
        <div className="buttons groupcompanies">
          <h4></h4>
          <LoadingButton loading={loading} onClick={exportData} loadingIndicator={<CircularProgress color="inherit" size={32} />}>
          インタラクティブ企業紹介用データ出力
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
