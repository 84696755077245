import { React } from "react";

export default function FolderIcon() {

  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20">
    <path id="Icon_material-folder-open" data-name="Icon material-folder-open" d="M25,7.5H15L12.5,5H5A2.5,2.5,0,0,0,2.513,7.5L2.5,22.5A2.507,2.507,0,0,0,5,25H25a2.507,2.507,0,0,0,2.5-2.5V10A2.507,2.507,0,0,0,25,7.5Zm0,15H5V10H25Z" transform="translate(-2.5 -5)" fill="#2f3d4a"/>
  </svg>
 );
}
