import { React, useState, useEffect } from "react";
import { Outlet, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialogEditable";
import LoadingButton from '@mui/lab/LoadingButton';

import './Publication.scss';

export default function PublicationEditMain(props) {
  const navigate = useNavigate();
  //let {id} = useParams();
  const [id, setId] = useState(props.id);
  const [publication, setPublication] = useState(props.publication); // 出来事データ
  const [media, setMedia] = useState(props.media); // 掲載場所
  const [company, setCompany] = useState(props.company); // 関連会社

  const { data, setMenu, publicationUpdate } = useMyData();

  const [yearProps, , setYear] = useInput("0000", "number"); // 年
  const [monthProps, , setMonth] = useInput("00", "number"); // 月
  const [dayProps, , setDay] = useInput("00", "number"); // 日

  const [mediaProps, , setTargetMedia] = useInput(""); // 媒体
  const [titleProps, , setTitle] = useInput(""); // タイトル
  const [nameProps, , setName] = useInput(data.name || ""); // 氏名
  const [postProps, , setPost] = useInput(data.post || ""); // 所属・部署名
  const [commentProps, , setComment] = useInput(""); // コメント

  const [loading, setLoading] = useState(false);

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [ret, setRet] = useState(true); // 登録時の戻り値


  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if (e) {
      setMedia(e);
    }
  }



  useEffect(() => {
    if (typeof publication.date !== 'undefined') {
      const dates = publication.date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTargetMedia(publication.media);
      setTitle(publication.title);
      setName(publication.user_name);
      setPost(publication.user_post);
      setComment(publication.comment);
    }
  }, [publication]);


  useEffect(() => {
    setMenu('publication');
  }, []);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useEffect(() => {
    setPublication(props.publication);
  }, [props.publication]);

  useEffect(() => {
    setMedia(props.media);
  }, [props.media]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if (yearProps.value === "") err = true;
    if (yearProps.value < 0) setYear(0);
    if (yearProps.value > 2500) setYear(2500);
    if (monthProps.value === "") err = true;
    if (monthProps.value < 0) setMonth(0);
    if (monthProps.value > 12) setMonth(12);
    if (dayProps.value === "") err = true;
    if (dayProps.value < 0) setDay(0);
    if (dayProps.value > 31) setDay(31);
    if (mediaProps.value === "") err = true;
    if (titleProps.value === "") err = true;
    if (postProps.value === "") err = true;
    if (nameProps.value === "") err = true;

    setHasError(err);
  });

  const updateData = () => {
    setLoading(true);
    publicationUpdate({
      v4: id,
      year: yearProps.value,
      month: monthProps.value,
      day: dayProps.value,

      media: mediaProps.value,
      title: titleProps.value,
      post: postProps.value,
      name: nameProps.value,
    }, setRet);
  }

  useEffect(() => {
    if (ret.status === 'OK') {
      setLoading(false);
      navigate('/publication/data/' + ret.v4);
    }
  }, [ret]);

  return (
    <div className="mainArea">
      <p>媒体一覧に登録したい情報を入力してください。</p>

      <dl>
        <dt className="required">年月日</dt>
        <dd>
          <input type="number" min="0" max="2500" {...yearProps} /> 年
          <input type="number" min="0" max="12" {...monthProps} /> 月
          <input type="number" min="0" max="31" {...dayProps} /> 日
        </dd>

        <dt className="required">
          媒体選択
          <button className="editTarget" onClick={openMediaDialog}>項目編集</button>
        </dt>
        <dd>
          <FormControl>
            <RadioGroup
              row
              {...mediaProps}
            >
              {media.map((data, i) =>
                <FormControlLabel key={data.id} value={data.id} control={<Radio
                  sx={{
                    color: "#4D4F5C",
                    '&.Mui-checked': {
                      color: "#26C1C9",
                    },
                  }} />} label={data.name} />
              )}
            </RadioGroup>
          </FormControl>
        </dd>

        <dt className="required">媒体タイトル</dt>
        <dd>
          <input type="text" {...titleProps} />
        </dd>

        <dt className="required">最終更新者</dt>
        <dd className="editor">
          <div>
            所属・部署名<br />
            <input type="text" {...postProps} />
          </div>
          <div>
            氏名<br />
            <input type="text" {...nameProps} />
          </div>
        </dd>

        <dt>最終更新日</dt>
        <dd>
          {publication.mdatetime ? publication.mdatetime : '------/--/--'}
        </dd>

      </dl>

      <div className="buttons">
        <LoadingButton loading={loading} disabled={hasError} onClick={updateData} loadingIndicator={<CircularProgress color="inherit" size={32} />}>
          更新
        </LoadingButton>
      </div>

      <MediaSelectDialog open={mediaDialogOpen} onClose={closeMediaDialog} values={media} />
    </div>
  );
}
