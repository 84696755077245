import { React, useState, useEffect, useCallback } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';


import './Dekigoto.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";

export default function DekigotoEditData(props) {
  const navigate = useNavigate();

  const { postDekigotoFile, loadDekigotoFiles } = useMyData();

  const [ id, setId ] = useState(props.id);

  const [loading,setLoading] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック

  const [files, setFiles] = useState([]); // Files

  let ffiles = [];

  useEffect(()=>{
    setId(props.id);
  },[props.id]);

  const updateData = () => {
    setLoading(true);
  }

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }


  useEffect(()=>{
     loadDekigotoFiles(id,setInitFiles);
  },[]);


  const setUploadedFile = (file) => {
    ffiles = [file, ...ffiles ];
    setFiles(ffiles);
  }

  const uploadFile = (file) => {
    postDekigotoFile(id,file,setUploadedFile);
  }

  const loadFiles = (d) => {
    loadDekigotoFiles(id,setInitFiles);
  } 

  return (
    <div className="mainArea dekigotoFile">
      <p>下記のボタンから資料をアップロードしてください。</p>
      
      <FileDropzone upload={uploadFile} />

      {files.length > 0 && 
        <div className="uploadText">アップロードした資料についての情報を入力してください。 <br /><span>メインとなる画像をひとつ選択</span>してください。</div>
      }

      <div>
        {files.map((data,i) => <FileData key={data.id} data={data} />)}
      </div>


    </div>
   );
}
