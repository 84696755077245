import { Fragment, React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";
import FileDownloadIcon from './Icons/FileDownloadIcon';
import ListAddIcon from './Icons/ListAddIcon';
import ArrowDownIcon from './Icons/ArrowDownIcon';
import BreadCrumbsIcon from './Icons/BreadCrumbsIcon';


import './FilesListDialog.scss';
import { AppBar, Checkbox, FormControlLabel, Toolbar, Typography } from "@mui/material";
import { MyButtonClose } from "./MyButtonClose";
import FilesListDialogList from "./FilesListDialogList";
import ConfirmDialog from "./ConfirmDialog";


export default function FilesListDialog({
  files = [], 
  title = '',
  id='',
  open = false, 
  onClose = f => f, 
  updateFile = f => f,
}) {
  const { data, fileDelete, loadJSON, downloadlist, setDownloadlist, publicationRmdir } = useMyData();

  const [dopen, setDOpen] = useState(false);
  const [path, setPath] = useState([]);
  const [dfiles, setDFiles] = useState([]);
  const [selectfile, setSelectfile] = useState("");
  const [filename, setFilename] = useState("");
  const [previewFilename, setPreviewFilename] = useState('sample.svg');
  
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    let prepath="";
    let newfiles = [];
    //let orgfiles = [...files];
    let orgfiles = files.map( file => ({...file}));

    let prefix = "";
    if(path.length > 0){
      prefix = "/" + path.join('/') + "/";

      orgfiles = orgfiles.filter( file => file.filepath.indexOf(prefix)==0 )
                 .map( (file,i) => {
                    let newfilename = file.filepath.substring(prefix.length - 1);
                    const matches = newfilename.match(/\//g);
                    if(matches.length == 1){
                      newfilename = newfilename.substring(1);
                    }
                    return { ...file,
                            isdir: 0,
                            filepath: newfilename,
                            filename: newfilename,
                          }});
    }

    let selected = 0;
    orgfiles.forEach(element => {
      if(element.id == selectfile){
        element.selected=1;
        selected = 1;
        setFilename ( element.filename );
        updateFileType(element);
      } else {
        element.selected=0;
      }

      if(element.filepath){
        let msgAry = element.filepath.split(/\//);
        if(msgAry.length > 1){
          element.filename = msgAry[1];
          if(element.filename != prepath) {
            prepath = element.filename;
            element.isdir = 1;
            newfiles.push(element);
          }
        } else {
          newfiles.push(element);
        }
      } else {
        newfiles.push(element);
      }
    });

    if(selected == 0) {
      setSelectfile("");
    }

    setDFiles(newfiles);
  }, [files,path,selectfile]);


  useEffect(() => {
    setDOpen(open);
  }, [open]);

  const handleClose = () => {
    setDOpen(false);
    onClose();
  };

  const updatePath = (addpath) => {
    setPath([...path, addpath]);
    setSelectfile("");
  }

  const selectFile = (id) => {
    setSelectfile(id);
  }

  const pathMove = (e) => {
    const newpath = path.slice(0,Number(e.currentTarget.dataset.num) + 1);
    setPath(newpath);
    setSelectfile("");
  }

  const deleteFile = (e) => {
    console.log(selectfile);
    console.log(dfiles);
    console.log(path);
    console.log(id);
    if (selectfile) {
      fileDelete(selectfile,updateFile);
    } else {
      publicationRmdir(id,path,updateFile);
    }
  }
  const closeDialog = (e) => {
    setConfirmDialogOpen(false);
  }
  const openDialog = (e) => {
    setConfirmDialogOpen(true);
  }
  const yesDialog = (e) => {
    setConfirmDialogOpen(false);
    deleteFile();
  }

  const updateFileType = (file) => {
    setPreviewFilename('sample.svg');
    if (file.filename.match(/\.xlsx?$/i)) {
      setPreviewFilename('icon_excel.png');
    }
    if (file.filename.match(/\.docx?$/i)) {
      setPreviewFilename('icon_word.png');
    }
    if (file.filename.match(/\.pdf?$/i)) {
      setPreviewFilename('icon_pdf.png');
    }
    if (file.mime_type.match(/^image\//i)) {
      setPreviewFilename('');
    }

  }



  const adddownload = (e) => {
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return downloadlist.indexOf(item.id) < 0 && item.id == selectfile;
      })
    ]);
    setDownloadlist([...set]);
  }


  const token = loadJSON("token");

  return (
    <>
      <Dialog
        open={dopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="filesListDialog"
        maxWidth={false}
      >
        <AppBar sx={{ position: 'relative' }} color="subcolor">
          <Toolbar sx={{ height: "89px" }}>
            <Typography sx={{ ml: 2, flex: 1, fontSize: "32px" }} variant="h6" component="div">
              付属データ
            </Typography>
            <MyButtonClose
              edge="end"
              onClick={handleClose}
              aria-label="close" />
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className="content">
            <div className="titleArea">
              <div className="title">
                <span className="breadcrumb" data-num="-1" onClick={pathMove}>{title}</span>
                {path.length > 0 && 
                <>
                {path.map((p,i)=>{
                  return <span key={i}><BreadCrumbsIcon /><span className="breadcrumb" data-num={i} onClick={pathMove}>{p}</span></span>
                })}
                </>
                }
              </div>
              <div className="buttons">
                {data.role>=3 &&
                <Button
                  variant="contained"
                  color="buttoncolor"
                  className="buttonDelete"
                  //disabled={!selectfile}
                  onClick={openDialog}
                >削除</Button>
                }
                <Button 
                  variant="contained"
                  color="buttoncolor"
                  className="buttonAddList"
                  disabled={!selectfile}
                  onClick={adddownload}
                ><ListAddIcon /> 　リストに追加</Button>
                <Button 
                  variant="contained"
                  color="buttoncolor"
                  className="buttonDownload"
                  disabled={!selectfile}
                  href={`${process.env.REACT_APP_API_ENDPOINT}file/${selectfile}/${token}`}
                  download={filename}
 
                ><FileDownloadIcon /> 　ダウンロード</Button>
              </div>
            </div>
            
            <div className={"items" + (selectfile ? " withPreview" : "")}>
              <div className="itemsHead">
                <div className="itemsHeadName">名前　<ArrowDownIcon /></div>
                <div className="itemsHeadDate">最終更新</div>
                <div className="itemsHeadSize">ファイルサイズ</div>
                <div className="itemsHeadPreview">プレビュー</div>
              </div>
              {dfiles.map((data, i) => <FilesListDialogList key={data.id} data={data} updatePath={updatePath} selectFile={selectFile} />)}
            </div>
            {selectfile !== "" && 
              <div id="preview">
                {previewFilename !== '' ?
                  <img src={`${process.env.PUBLIC_URL}/img/${previewFilename}`} />
                  :
                  <img src={`${process.env.REACT_APP_API_ENDPOINT}file/${selectfile}/${token}`} />
                }
              </div>
            }
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmDialog open={confirmDialogOpen} onClose={closeDialog} onYes={yesDialog} title="確認" msg={selectfile ? "このファイルを削除します" : "このフォルダ配下のファイルを全て削除します"} />
    </>
 );

}
