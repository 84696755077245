import { React, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import './My.scss';

export function MySearch(props) {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);


  const theme = createTheme(
    {
      palette: {
        primary: { 
          main: '#BDECEF' ,
          dark: '#BDECEF' 
        },
      },
    },
  );

  const clearWord = () => {
    setValue('');
    const e = {target: {value : ''}};
    props.onChange(e);
  }
  const handleChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ mr: 1 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={clearWord}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }}


        className="my"
        variant="filled" 
        type="text"
        sx={{ width: '70ch' }}
        value={value}
        onChange={handleChange}
      />
    </ThemeProvider>
  );
}

