import { React, useEffect, useState, useRef } from 'react';
import { useMyData } from './MyProvider';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';

import './FileData.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
//import FileDownloadIcon from './Icons/FileDownloadIcon';
import FileDownloadIcon from './Icons/FileDownloadIcon';
import KeepPinIcon from './Icons/KeepPinIcon';
import TrashIcon from './Icons/TrashIcon';


export default function FileDataView(props) {
  const { data, loadJSON } = useMyData();

  const [id, setId] = useState(props.data.id);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [filename, setFilename] = useState('sample.svg');

  const ref = useRef(null)

  useEffect(() => {
    if (props.data.filename.match(/\.xlsx?$/i)) {
      setFilename('icon_excel.png');
    }
    if (props.data.filename.match(/\.docx?$/i)) {
      setFilename('icon_word.png');
    }
    if (props.data.filename.match(/\.pdf?$/i)) {
      setFilename('icon_pdf.png');
    }
    if (props.data.mime_type.match(/^image\//i)) {
      setFilename('');
    }
  }, []);

  const loadedImg = () => {
    setImgWidth(ref.current.clientWidth);
    setImgHeight(ref.current.clientHeight);
  }

  const keepImage = (e) => {
    props.keepImage(e);
  }

  const token = loadJSON("token");

  if(props.mode === "tile" && ! props.show) {
    return (<></>);
  }
  if(props.mode === "image" && ! props.show) {
    return (<></>);
  }

  return (
    <div className={"fileDataView" + (props.mode === "dialog" ? " dialog" : "") }>
      <div className={"thumbnail"}>
        <div>
          {filename !== '' ?
            <img ref={ref} src={`${process.env.PUBLIC_URL}/img/${filename}`} onLoad={loadedImg} />
            :
            <img ref={ref} src={`${process.env.REACT_APP_API_ENDPOINT}file/${id}/${token}`} onLoad={loadedImg} />
          }
          {props.data.nodownload == 1 &&
          <div className="overflow" style={{ width: imgWidth, height: imgHeight }}></div>
          }
        </div>
      </div>

      {props.mode !== "tile" && props.mode !== "image" &&
      <div className="description">
        {props.data.description}
      </div>
      }

      {props.mode === "dialog" &&
        <div className="data">
          <div className='filename'>
            {props.data.filename}
            {" "}
            (
            {props.data.filesize > 1000000 ?
              <>{Math.round(props.data.filesize / 1000000).toLocaleString()}MB</>
              :
              <>{Math.round(props.data.filesize / 1000).toLocaleString()}KB</>
            }
            )
          </div>
          {props.data.nodownload == 0 && data.role >= 2 &&
            <div className='buttons'>
              <Button
                variant="contained"
                color="buttoncolor"
                href={`${process.env.REACT_APP_API_ENDPOINT}file/${id}/${token}`}
                download={props.data.filename}
              >
                <FileDownloadIcon /> 　ダウンロードする
              </Button>
              <Button
                variant="contained"
                color="buttoncolor"
                onClick={keepImage}
                value={id}
              >
                <KeepPinIcon /> 　{props.target == 'download' ? '一括ダウンロードリスト' : 'キープリスト'}に追加
              </Button>
              {props.target == 'download' && 
                <Button
                  variant="contained"
                  color="buttoncolor"
                  onClick={props.deleteItem}
                  value={id}
                >
                  <TrashIcon /> 　削除
                </Button>
              }
            </div>
          }
        </div>
      }

    </div>
  )
}