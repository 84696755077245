import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, TextareaAutosize } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './Company.scss';
import { Label } from "@mui/icons-material";
import CompanyDataCapital from "./CompanyDataCapital";
import CompanyDataSales from "./CompanyDataSales";
import CompanyDataStake from "./CompanyDataStake";
import CompanyDataEmployee from "./CompanyDataEmployee";
import CompanyDataStore from "./CompanyDataStore";
import MediaSelectDialog from "./MediaSelectDialogEditable";

export default function CompanyEditMain(props) {
  const [ id, setId ] = useState(props.id);
  const [ company, setCompany ] = useState(props.company); // 企業データ
  const [ media, setMedia ] = useState(props.media); // 掲載場所

  const { data,companyUpdate,companyCategories,zip2addr } = useMyData();
  const navigate = useNavigate();

  const [yearProps,,setYear] = useInput("0000","number"); // 設立年
  const [monthProps,,setMonth] = useInput("00","number"); // 設立月
  const [dayProps,,setDay] = useInput("00","number"); // 設立日

  const [titleProps,,setTitle] = useInput(""); // 企業名
  const [titleYomiProps,,setTitleYomi] = useInput(""); // 企業名ふりがな
  const [titleEnProps,,setTitleEn] = useInput(""); // 英文企業名
  const [ceoProps,,setCeo] = useInput(""); // 代表取締役

  // 資本金
  const [capitals,setCapitals] = useState([]);

  // 売上金
  const [salesType,setSalesType] = useState(0);
  const [sales,setSales] = useState([]);

  // 出資比率
  const [stakes,setStakes] = useState([]);

  // 従業員数
  const [employees,setEmployee] = useState([]);

  // 店舗数
  const [stores,setStores] = useState([]);

  // 所在地
  const [zipProps,,setZip] = useInput("","number"); // 郵便番号
  const [prefProps,,setPref] = useInput(""); // 都道府県
  const [addr1Props,,setAddr1] = useInput(""); // 住所1
  const [addr2Props,,setAddr2] = useInput(""); // 住所2
  const prefs = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ];

  const [categoryProps,,setCategory] = useInput(0); // 事業分類
  const [companyCategory,setCompanyCategory] = useState([]); // 事業分類一覧
  const [companies,setCompanies] = useState([]); // 事業分類用に会社一覧取得
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);


  const [commanderProps,,setCommander] = useInput(""); // 作業指示者
  const [nameProps,,setName] = useInput(data.name||""); // 氏名
  const [postProps,,setPost] = useInput(data.post||""); // 所属・部署名
  const [commentProps,,setComment] = useInput(""); // コメント


  const [loading,setLoading] = useState(false);


  const [hasError, setHasError] = useState(true); // エラーチェック
  const [ret, setRet] = useState(true); // 登録時の戻り値


  useEffect(()=>{
    if(companies.length > 0){
      setCompanyCategory(companyCategories(companies));
    }
  },[companies]);

  useEffect(()=>{
    if(typeof company.foundation_date !== 'undefined' ){
      const dates = company.foundation_date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(company.name);
      setTitleYomi(company.yomi);
      setTitleEn(company.name_en);
      setCeo(company.ceo);

      setCapitals(company.capitals);
      setSalesType(company.sales_type);
      setSales(company.sales);
      setStakes(company.stakes);
      setEmployee(company.employees);
      setStores(company.stores);
      setCategory(company.category);
      setZip(company.zip);
      setPref(company.pref);
      setAddr1(company.addr1);
      setAddr2(company.addr2);

      setCommander(company.commander);
      setName(company.user_name);
      setPost(company.user_post);
      setComment(company.comment);
    }
  },[company]);

  useEffect(()=>{
    setCompany(props.company);
  },[props.company]);

  useEffect(()=>{
    setMedia(props.media);
  },[props.media]);

  useEffect(()=>{
    setCompanies(props.companies);
  },[props.companies]);
  
  // エラーチェック
  useEffect(() => {
    let err = false;

    if(yearProps.value==="") err=true;
    if(yearProps.value < 0 ) setYear(0);
    if(yearProps.value > 2500 ) setYear(2500);
    if(monthProps.value==="") err=true;
    if(monthProps.value < 0 ) setMonth(0);
    if(monthProps.value > 12 ) setMonth(12);
    if(dayProps.value==="") err=true;
    if(dayProps.value < 0 ) setDay(0);
    if(dayProps.value > 31 ) setDay(31);
    if(titleProps.value==="") err=true;
    if(postProps.value==="") err=true;
    if(nameProps.value==="") err=true;
    if(categoryProps.value==0) err=true;
    setHasError(err);
  });

  const updateData = () => {
    setLoading(true);
    companyUpdate({
      v4: id,
      year: yearProps.value,
      month: monthProps.value,
      day: dayProps.value,

      title: titleProps.value,
      title_yomi: titleYomiProps.value,
      title_en: titleEnProps.value,
      ceo:ceoProps.value,

      capitals: capitals,
      sales_type: salesType,
      sales: sales,
      stakes: stakes,
      employees: employees,
      stores: stores,

      category: categoryProps.value,
      media: media,

      zip: zipProps.value,
      pref: prefProps.value,
      addr1: addr1Props.value,
      addr2: addr2Props.value,

      commander:commanderProps.value,
      post:postProps.value,
      name:nameProps.value,
      comment:commentProps.value,
    },setRet);
  }

  const zip2addrHandler = () => {
    zip2addr(setPref,setAddr1,zipProps.value);
  }

  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if(e){
      setMedia(e);
    }
  }


  useEffect(()=>{
    if(ret.status==='OK'){
      navigate('/company/data/' + ret.v4);
    }
  },[ret]);



  return (
    <>
      <div className="mainArea">
        <dl>
          <dt className="required">企業名</dt>
          <dd>
            <input className="company" type="text" {...titleProps} />
          </dd>

          <dt>企業名ふりがな</dt>
          <dd>
            <input className="company" type="text" {...titleYomiProps} />
          </dd>

          <dt>英文企業名</dt>
          <dd>
            <input className="company" type="text" {...titleEnProps} />
          </dd>

          <dt>代表取締役</dt>
          <dd>
            <input className="company" type="text" {...ceoProps} />
          </dd>

          <dt>設立年月日</dt>
          <dd>
            <input className="company" type="number" min="0" max="2500" {...yearProps} /> 年　
            <input className="company" type="number" min="0" max="12" {...monthProps} /> 月　
            <input className="company" type="number" min="0" max="31" {...dayProps} /> 日　
          </dd>

          <dt>資本金</dt>
          <dd>
            <CompanyDataCapital values={capitals} onChange={setCapitals} />
          </dd>

          <dt>売上高</dt>
          <dd>
            <CompanyDataSales values={sales} salesType={salesType} onChange={setSales} onTypeChange={setSalesType} />
          </dd>

          <dt>出資比率</dt>
          <dd>
            <CompanyDataStake values={stakes} onChange={setStakes} />
          </dd>

          <dt>従業員数</dt>
          <dd>
            <CompanyDataEmployee values={employees} onChange={setEmployee} />
          </dd>

          <dt>店舗数</dt>
          <dd>
            <CompanyDataStore values={stores} onChange={setStores} />
          </dd>

          <dt className="required">事業分類選択</dt>
          <dd>
            <FormControl>
              <RadioGroup
                row
                {...categoryProps}
              >
                {companyCategory.map((data,i) => 
                  <FormControlLabel key={data.category} value={data.category} control={<Radio
                    sx={{
                      color: "#4D4F5C",
                      '&.Mui-checked': {
                        color: "#26C1C9",
                      },
                    }}/>} label={data.category_name} />
                )}
              </RadioGroup>
            </FormControl>
          </dd>

          <dt>掲載場所</dt>
          <dd>
            <button className="company" onClick={openMediaDialog}>選択</button>
            <div className="company list">
              {media.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
            </div>
          </dd>


          <dt>所在地</dt>
          <dd>
            <label className="label">
              <span>郵便番号　※ハイフンは必要ありません</span>
              <input className="company zip" {...zipProps} /> 　
              <button className="zip" onClick={zip2addrHandler} disabled={zipProps.value==""}>郵便番号から住所を検索</button>
            </label>
            <label className="label">
              <span>都道府県</span>
              <Autocomplete
                freeSolo
                className="select pref"
                options={prefs}
                renderInput={(params) => <TextField {...params} variant="filled" />}
                value={prefProps.value}
                onChange={(event, newValue) => {
                  setPref(newValue);
                }}
              />
            </label>
            <label className="label">
              <span>市区町村</span>
              <input className="company addr" {...addr1Props} /> 　
            </label>
            <label className="label">
              <span>番地以降</span>
              <input className="company addr" {...addr2Props} /> 　
            </label>
          </dd>



          <dt>作業指示者</dt>
          <dd>
            <input className="company" type="text" {...commanderProps} />
          </dd>

          <dt className="required">最終更新者</dt>
          <dd className="editor">
            <div>
              所属・部署名<br />
              <input className="company" type="text" {...postProps} />
            </div>
            <div>
              氏名<br />
              <input className="company" type="text" {...nameProps} />
            </div>
          </dd>

          <dt>最終更新日</dt>
          <dd>
            {company.mdatetime ? company.mdatetime : '------/--/--'}
          </dd>

          <dt>コメント</dt>
          <dd>
            <TextareaAutosize  {...commentProps} />
          </dd>

        </dl>

        <div className="buttons">
          <LoadingButton loading={loading} disabled={ hasError } onClick={updateData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
            更新
          </LoadingButton>
        </div>

      </div>

      <MediaSelectDialog open={mediaDialogOpen} onClose={closeMediaDialog} values={media}  />
    </>
 );
}
