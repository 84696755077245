import {React, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

import './FileDropzone.scss';

export default function FileDropzone(props) {

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(element => props.upload(element));
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className="fileDropzone">
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p className='dropArea'>ファイルをこのエリアにドロップ</p> :
          <p>資料アップロード<span>(ドラッグアンドドロップ または クリック)</span></p>
      }
    </div>
  )
}