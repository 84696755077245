import { React, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, MenuItem, Select } from "@mui/material";

import './My.scss';
import ListIcon from "./Icons/ListIcon";
import ListTileIcon from "./Icons/ListTileIcon";
import ListImageIcon from "./Icons/ListImageIcon";

export function MySelectListType({value = "list", onChange = f => f}) {


  const theme = createTheme(
    {
      palette: {
        primary: { 
          main: '#26C1C9' ,
          dark: '#26C1C9' ,
        },
      },
    },
  );

  
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="filled" className="my selectListType" sx={{ m: 0, minWidth: 120 }}>
        <Select
          value={value}
          onChange={onChange}
        >
          <MenuItem value="list">
            <ListIcon />　リスト表示
          </MenuItem>
          <MenuItem value="tile">
            <ListTileIcon />　タイル表示
          </MenuItem>
          <MenuItem value="image">
            <ListImageIcon />　画像表示
          </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

