import { React } from "react";

export default function ListImageIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.692" viewBox="0 0 20 16.692">
      <path id="picture" d="M0,2.977V19.669H20V2.977Zm1.534,1.56H18.466V18.109H1.534ZM5.024,6.5A1.668,1.668,0,0,0,3.331,8.19a1.6,1.6,0,0,0,.488,1.172,1.637,1.637,0,0,0,1.2.488,1.667,1.667,0,0,0,1.66-1.66,1.638,1.638,0,0,0-.488-1.2A1.6,1.6,0,0,0,5.024,6.5Zm7.2.825L8.378,13.149l-2.04-1.693L3.331,15.363V16.5H16.668v-4.58l-4.449-4.6Z" transform="translate(0 -2.977)"/>
    </svg>
 );
}
