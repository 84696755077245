import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";

import './CompanySelectDialogBig.scss';
import { Checkbox, FormControlLabel, Toolbar, Typography } from "@mui/material";
import { MyButtonClose } from "./MyButtonClose";


export default function CompanySelectDialogBig(props) {
  const { getDekigotoCount, companyCategories } = useMyData();

  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState([]); // 関連企業
  const [companyCat, setCompanyCat] = useState([]); // 関連企業カテゴリー
  const [selectedCat, setSelectedCat] = useState(0); // 選択中のカテゴリー
  const [allChecked, setAllChecked] = useState(0);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleYes = () => {
    setOpen(false);
    props.onClose(company);
  };

  useEffect(() => {
    setCompanyCat(companyCategories(company));
  }, [company.length]);


  const setData = (e) => {
    setCompany(
      company.map(item => {
        if (Number(item.id) === Number(e.target.value)) {
          return {
            ...item,
            checked: e.target.checked
          }
        } else {
          return item;
        }
      })
    )
  }

  const handleChange = (event) => {
    setAllChecked(event.target.checked ? 1 : 0);
    setCompany(
      company.map(item => {
        if(item.category==selectedCat){
          return {
            ...item,
            checked: event.target.checked
          }
        } else {
          return item;
        }
      })
    )
  }

  const handleClear = () => {
    setAllChecked(false);
    setCompany(
      company.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    )
  }

  const catSelect = (e) => {
    setSelectedCat(e.currentTarget.dataset.val);

    setCompanyCat(
      companyCat.map(item => {
        if (item.category == e.currentTarget.dataset.val) {
          return {
            ...item,
            select: true
          }
        } else {
          return {
            ...item,
            select: false
          }
        }
      })
    )

  }

  useEffect(() => {
    setCompany(props.values);
  }, [props.values]);

  useEffect(() => {
    const comp = company.filter((item)=>{return item.category==selectedCat}).filter(item => { return item.checked }).length;

    if (company.filter((item)=>{return item.category==selectedCat}).length == comp) {
      setAllChecked(1);
    } else if (comp == 0) {
      setAllChecked(0);
    } else {
      setAllChecked(-1);
    }
  }, [company,selectedCat]);

  useEffect(() => {
    if (
      company.filter(item => {
        return item.checked
      }).length == 0
    ) {
      setCount(0);
    } else {
      searchHandler();
    }
  }, [company]);



  const searchHandler = () => {
    const searchParam = {
      media: [],
      company: company, // 関連企業
    };
    getDekigotoCount({
      ...searchParam
    }, setCount);
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="companyDialogBig"
        maxWidth={false}
      >

        <Toolbar sx={{ height: "89px" }}>
          <Typography sx={{ ml: 2, flex: 1, fontSize: "32px" }} variant="h6" component="div">
            関連企業を選択
          </Typography>
          <MyButtonClose
            edge="end"
            onClick={handleClose}
            aria-label="close" />
        </Toolbar>


        <DialogContent>

          <div className="selects">
            <div className="catAndCompany">
              <div className="category">
                {companyCat.map((data, i) =>
                  <div key={data.category}
                    className={data.select ? "select" : ""}
                    onClick={catSelect}
                    data-val={data.category}
                  >{data.category_name}</div>
                )}

              </div>
              {selectedCat > 0 &&
              <div className="company">
                <div className="selectAll">
                  <FormControlLabel control={
                    <Checkbox
                      checked={allChecked == 1}
                      onChange={handleChange}
                      indeterminate={allChecked == -1}
                      sx={{
                        color: "#4D4F5C",
                        '&.Mui-checked': {
                          color: "#26C1C9",
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: "#26C1C9",
                        },
                      }} />
                  } label={companyCat.filter((item)=>{return item.category==selectedCat})[0]['category_name']+"全て"} />
                  
                </div>
                <div className="items">
                  {company.filter((item)=>{return item.category==selectedCat}).map((data, i) =>
                    <FormControlLabel key={i} className="item" control={
                      <Checkbox
                        checked={data.checked ? true : false}
                        onChange={setData}
                        value={data.id}
                        sx={{
                          color: "#4D4F5C",
                          '&.Mui-checked': {
                            color: "#26C1C9",
                          },
                        }} />
                    } label={data.name} />
                  )}
                </div>
              </div>
              }
            </div>
          </div>



          <div className="selected">
            <div className="selectedlist">
              <span>選択している分類</span>
              <span className="clear" onClick={handleClear}>全てクリア</span>
              <div>
                {company.filter((data) => { return data.checked }).map((data, i) => <span key={i}>{data.name}</span>)}
              </div>
            </div>
            <div className="selectedresult">
              <span>検索結果:</span>
              <span className="count">{count} 件</span>
              <button onClick={handleYes}>選択項目を反映</button>
            </div>
          </div>
        </DialogContent>

      </Dialog>
    </>
  );

}
