import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, Backdrop, TextField, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";

import './Publication.scss';
import { MyDatePicker } from "./MyDatePicker";
import { MySelectYear } from "./MySelectYear";
import { MySelectMonth } from "./MySelectMonth";
import { MyButton } from "./MyButton";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MySearch } from "./MySearch";
import { MySelectListType } from "./MySelectListType";
import { MySelectCount } from "./MySelectCount";
import { MySelectSort } from "./MySelectSort";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DekigotoListList from "./DekigotoListList";
import DekigotoListTile from "./DekigotoListTile";
import DekigotoListImage from "./DekigotoListImage";
import MediaSelectDialogBig from "./MediaSelectDialogBig";
import CompanySelectDialogBig from "./CompanySelectDialogBig";
import PublicationListMedia from "./PublicationListMedia";

export default function PublicationList() {

  const { param, setParam, setMenu, getPublicationList, getMedias, getCompanies } = useMyData();
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openAll, setOpenAll] = useState(false);
  const [closeAll, setCloseAll] = useState(false);


  const [syearProps,, setSYear] = useInput(param.syear || "","number"); // 年
  const [smonthProps,, setSMonth] = useInput(param.smonth || "","number"); // 月
  const [eyearProps,, setEYear] = useInput(param.eyear || "","number"); // 年
  const [emonthProps,, setEMonth] = useInput(param.emonth || "","number"); // 月

  const [allChecked, setAllChecked] = useState(0); // 全媒体を選択／解除

  useEffect(() => {
    setMenu('publicationlist');
  }, []);

  //useEffect(() => {
  //  searchHandler();
  //}, [syearProps.value, smonthProps.value, eyearProps.value, emonthProps.value, ]);

  useEffect(() => {
    searchHandler();
  } , []);


  useEffect(() => {
    const open = list.filter(item => {return item.checked && item.open}).length;
    const close = list.filter(item => {return item.checked && !item.open}).length;

    setOpenAll(close > 0);
    setCloseAll(open > 0);
  }, [list]);

  const handleOpenAll = () => {
    setList(
      list.map(item => {
        if(item.checked) {
          return {
            ...item,
            open: true,
          }
        } else {
          return item
        }
      })
    );
    
  }
  const handleCloseAll = () => {
    setList(
      list.map(item => {
        if(item.checked) {
          return {
            ...item,
            open: false,
          }
        } else {
          return item
        }
      })
    );
    
  }

  const searchHandler = () => {
    setLoading(true);
    const searchParam = {
      syear : syearProps.value, // 年
      smonth : smonthProps.value, // 月
      eyear : eyearProps.value, // 年
      emonth : emonthProps.value, // 月
    };
    getPublicationList({
      ...searchParam
    }, setList, setLoading);
  }

  const handleChange = (event) => {
    setAllChecked(event.target.checked ? 1 : 0);
    setList(
      list.map(item => {
        return {
          ...item,
          checked: event.target.checked
        }
      })
    );
  }

  const handleMediaChange = (v4, val) => {
    setList(
      list.map(item => {
        if(item.v4 == v4) {
          return {
            ...item,
            checked: val,
          }
        } else {
          return item
        }
      })
    );
  }

  const handleOpenClose = (v4, val) => {
    setList(
      list.map(item => {
        if(item.v4 == v4) {
          return {
            ...item,
            open: val,
          }
        } else {
          return item
        }
      })
    );
  }

  useEffect(() => {
    const comp = list.filter(item => {return item.checked}).length;

    if(list.length == comp) {
      setAllChecked(1);  
    } else if(comp==0) {
      setAllChecked(0);  
    } else {
      setAllChecked(-1);  
    }
  }, [list]);

  const updateFilesLocal = (v4, v4pub, files) => {
    console.log(v4 + " :: " + v4pub);
    console.log(files);
    setList(
      list.map(item => {
        if(item.v4 == v4) {

          const pubs = item.pubs.map(p => {
            if(p.v4 == v4pub) {
              return {
                ...p,
                files: files,
              }
            } else {
              return p;
            }
          })

          return {
            ...item,
            pubs: pubs,
          }
        } else {
          return item
        }
      })
    );
  }

  return (
    <div className="publication">
      媒体一覧
      <div className="tableBase nopadding bottomSpace">
        <dl className="searchConditions">
          <dt>期間</dt>
          <dd>
            <Grid container direction="row" alignItems="center">
              <input type="number" min="0" max="2500" {...syearProps} /> 年　
              <input type="number" min="0" max="12" {...smonthProps} /> 月　
              〜　
              <input type="number" min="0" max="2500" {...eyearProps} /> 年　
              <input type="number" min="0" max="12" {...emonthProps} /> 月　

              <MyButton onClick={searchHandler}>検索</MyButton>
            </Grid>
          </dd>
        </dl>
      </div>

      <div className="publicationListBase">
        {loading ? 
        <div className="loading">
          <CircularProgress />
        </div>
        :
        <>
          {list && list.length > 0 ?
           <>
            <div className="mediaHead">
              <FormControlLabel control={
                <Checkbox 
                  checked={allChecked==1}
                  onChange={handleChange}
                  indeterminate={allChecked==-1}
                  sx={{
                    color: "#4D4F5C",
                    '&.Mui-checked': {
                      color: "#26C1C9",
                    },
                    '&.MuiCheckbox-indeterminate': {
                      color: "#26C1C9",
                    },
                  }} />
                } label="全媒体を選択／解除" />
                <div>
                <button disabled={!openAll} onClick={handleOpenAll}>すべて展開</button>
                <button disabled={!closeAll} onClick={handleCloseAll}>折りたたむ</button>
                </div>
            </div>
            {list && list.map((data, i) => 
              <PublicationListMedia data={data} data-v4={data.id} key={i} handleChange={handleMediaChange} handleOpenClose={handleOpenClose} updateFiles={updateFilesLocal} />
            )}
           </>
          :
          <div>
            条件にマッチする「媒体」は、ありません。<br /><br />
          </div>
          }
        </>
        }
      </div>


    </div>
  );
}
