import { React, useState, useEffect, useCallback } from "react";
import { Outlet, useLocation,Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, Dialog, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialog";
import CompanySelectDialog from "./CompanySelectDialog";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDropzone} from 'react-dropzone';


import './Company.scss';
import FileDropzone from "./FileDropzone";
import FileData from "./FileData";

export default function CompanyPreviewDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="910"
    >

      <div className="companyPreview">
        <div className="year">{props.year}年</div>
        <div className="month">{props.month}月</div>
        <div className="content">{props.content}</div>
      </div>

    </Dialog>
   );
}
