import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

import './Company.scss';
import DekigotoEditMain from "./DekigotoEditMain";
import DekigotoEditData from "./DekigotoEditData";
import DekigotoEditPreview from "./DekigotoEditPreview";
import { Button } from "@mui/material";
import { MyButton } from "./MyButton";
import { MyButtonBack } from "./MyButtonBack";
import { MyButtonEdit } from "./MyButtonEdit";
import CompanyEditPreview from "./CompanyEditPreview";

export default function CompanyDetail() {
  let {id} = useParams();
  const navigate = useNavigate();

  const { data, setMenu,getCompanyMedias,getCompanies,getCompany } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル

  const [media,setMedia] = useState([]); // 掲載場所
  const [companies,setCompanies] = useState({}); // 企業情報
  const [company,setCompany] = useState({}); // 企業情報


  const [tab,setTab] = useState(0); // TAB

  useEffect(()=>{
    getCompanyMedias(setMedia,id);
  },[id]);

  useEffect(()=>{
    getCompanies(setCompanies,id);
  },[id]);

  useEffect(()=>{
    getCompany(setCompany,id);
  },[id]);

  useEffect(()=>{
    if(typeof company.foundation_date !== 'undefined' ){
      const dates = company.foundation_date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(company.name);
    }
  
  },[company]);


  useEffect(()=>{
    setMenu('companylist');
  },[]);

  const back = () => {
    navigate('/company/list/');
  }
  const edit = () => {
    navigate('/company/data/' + id);
  }

  return (
    <div className="company dekigotodetail">
      <div className="buttons">
        <MyButtonBack onClick={back}>
          検索結果に戻る
        </MyButtonBack>
        {data.role>=3 &&
        <MyButtonEdit onClick={edit}>
          この企業を編集
        </MyButtonEdit>
        }
      </div>
      <div className="detail">
        {company.foundation_date && <CompanyEditPreview id={id} company={company} companies={companies} media={media} />}
      </div>
    </div>
 );
}
