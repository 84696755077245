import { React, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, MenuItem, Select } from "@mui/material";

import './My.scss';

export function MySelectYear(props) {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event);
  }

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#26C1C9' ,
          dark: '#26C1C9' ,
        },
      },
    },
  );

  function* range (start, end) {
    for (let i = start; i <= end; i++) {
      yield i;
    }
  }
  
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="filled" className="my" sx={{ m: 0, minWidth: 120 }}>
        <Select
          value={value}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>(なし)</em>
          </MenuItem>
          { [...range(1900, 2050)].map((value) => <MenuItem key={value} value={value.toString()}>{value}</MenuItem>)}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

