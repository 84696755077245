import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";

import './MediaSelectDialogBig.scss';
import { AppBar, Checkbox, FormControlLabel, Toolbar, Typography } from "@mui/material";
import { MyButtonClose } from "./MyButtonClose";


export default function MediaSelectDialogBig(props) {
  const { getDekigotoCount } = useMyData();

  const [open, setOpen] = useState(false);
  const [media,setMedia] = useState([]); // 掲載場所
  const [allChecked, setAllChecked] = useState(0);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleYes = () => {
    setOpen(false);
    props.onClose(media);
  };
  
  const setData = (e) => {
    setMedia(
      media.map(item => {
        if (Number(item.id) === Number(e.target.value)) {
          return {
            ...item,
            checked: e.target.checked
          }
        } else {
          return item;
        }
      })
    )
  }

  const handleChange = (event) => {
    setAllChecked(event.target.checked ? 1 : 0);
    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: event.target.checked
        }
      })
    )
  }

  const handleClear = () => {
    setAllChecked(false);
    setMedia(
      media.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    )
  }

  useEffect(() => {
    setMedia(props.values);
  }, [props.values]);

  useEffect(() => {
    const comp = media.filter(item => {return item.checked}).length;

    if(media.length == comp) {
      setAllChecked(1);  
    } else if(comp==0) {
      setAllChecked(0);  
    } else {
      setAllChecked(-1);  
    }
  }, [media]);
  
  useEffect(() => {
    if(
      media.filter(item => {
        return item.checked
      }).length == 0
    ) {
      setCount(0);
    } else {
      searchHandler();
    }
  }, [media]);



  const searchHandler = () => {
    const searchParam = {
      media : media, // 掲載場所
      company: [],
    };
    getDekigotoCount({
      ...searchParam
    }, setCount);
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mediaDialogBig"
        maxWidth={false}
      >
        
        <Toolbar sx={{ height: "89px" }}>
          <Typography sx={{ ml: 2, flex: 1, fontSize: "32px" }} variant="h6" component="div">
            掲載場所を選択
          </Typography>
          <MyButtonClose
            edge="end"
            onClick={handleClose}
            aria-label="close" />
        </Toolbar>
        

        <DialogContent>
          <div className="selects">
            <div className="selectAll">
              <FormControlLabel control={
                  <Checkbox 
                    checked={allChecked==1}
                    onChange={handleChange}
                    indeterminate={allChecked==-1}
                    sx={{
                      color: "#4D4F5C",
                      '&.Mui-checked': {
                        color: "#26C1C9",
                      },
                      '&.MuiCheckbox-indeterminate': {
                        color: "#26C1C9",
                      },
                    }} />
                  } label="全項目選択" />
            </div>
            <div className="items">
            {media.map((data,i) => 
                <FormControlLabel key={i} className="item" control={
                  <Checkbox 
                    checked={data.checked ? true : false}
                    onChange={setData}
                    value={data.id}
                    sx={{
                      color: "#4D4F5C",
                      '&.Mui-checked': {
                        color: "#26C1C9",
                      },
                    }} />
                  } label={data.name} />
            )}
            </div>
          </div>
          
          <div className="selected">
            <div className="selectedlist">
              <span>選択している分類</span>
              <span className="clear" onClick={handleClear}>全てクリア</span>
              <div>
                {media.filter((data)=>{return data.checked}).map((data,i) => <span key={i}>{data.name}</span> )}
              </div>
            </div>
            <div className="selectedresult">
              <span>検索結果:</span>
              <span className="count">{count} 件</span>
              <button onClick={handleYes}>選択項目を反映</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
 );

}
