import { React, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, MenuItem, Select } from "@mui/material";

import './My.scss';

export function MySelectCount({value = 100, onChange = f => f}) {


  const theme = createTheme(
    {
      palette: {
        primary: { 
          main: '#26C1C9' ,
          dark: '#26C1C9' ,
        },
      },
    },
  );

  
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="filled" className="my" sx={{ m: 0, minWidth: 120 }}>
        <Select
          value={value}
          onChange={onChange}
        >
          <MenuItem value="50">
            50件表示
          </MenuItem>
          <MenuItem value="100">
            100件表示
          </MenuItem>
          <MenuItem value="200">
            200件表示
          </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

