import { React } from "react";

export default function PlusIcon() {

  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="グループ_10963" data-name="グループ 10963" transform="translate(-412 -324)">
      <g id="チェックマークベース" transform="translate(412 324)" fill="#fff" stroke="#26c1c9" strokeWidth="1">
        <rect width="24" height="24" rx="3" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="none"/>
      </g>
      <line id="線_29" data-name="線 29" x2="14" transform="translate(417 336)" fill="none" stroke="#26c1c9" strokeLinecap="round" strokeWidth="2"/>
      <line id="線_45" data-name="線 45" x2="14" transform="translate(424 329) rotate(90)" fill="none" stroke="#26c1c9" strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>
 );
}
