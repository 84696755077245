import { React } from "react";

export default function TrashIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.071" height="19.698" viewBox="0 0 17.071 19.698">
      <g id="trash" transform="translate(-3.375)">
        <path id="パス_10038" data-name="パス 10038" d="M11.883,12.375a.633.633,0,0,1,.633.633v7.6a.633.633,0,0,1-1.267,0v-7.6A.633.633,0,0,1,11.883,12.375Zm3.167,0a.633.633,0,0,1,.633.633v7.6a.633.633,0,0,1-1.267,0v-7.6A.633.633,0,0,1,15.05,12.375Zm3.8.633a.633.633,0,1,0-1.267,0v7.6a.633.633,0,0,0,1.267,0Z" transform="translate(-3.14 -4.929)" fill="#2e3d49"/>
        <path id="パス_10039" data-name="パス 10039" d="M20.446,3.94a1.313,1.313,0,0,1-1.313,1.313h-.657V17.071A2.626,2.626,0,0,1,15.85,19.7H7.971a2.626,2.626,0,0,1-2.626-2.626V5.253H4.688A1.313,1.313,0,0,1,3.375,3.94V2.626A1.313,1.313,0,0,1,4.688,1.313h4.6A1.313,1.313,0,0,1,10.6,0h2.626a1.313,1.313,0,0,1,1.313,1.313h4.6a1.313,1.313,0,0,1,1.313,1.313ZM6.813,5.253l-.155.078V17.071a1.313,1.313,0,0,0,1.313,1.313H15.85a1.313,1.313,0,0,0,1.313-1.313V5.33l-.155-.078ZM4.688,3.94V2.626H19.133V3.94Z" transform="translate(0 0)" fill="#2e3d49" fillRule="evenodd"/>
      </g>
    </svg>
 );
}
