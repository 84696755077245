import { React, useState, useEffect } from "react";
import 'dayjs/locale/ja';
import { LocalizationProvider, DatePicker, jaJP } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import './My.scss';

export function MyDatePicker(props) {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  }

  const theme = createTheme(
    {
      palette: {
        primary: { 
          main: '#BDECEF' ,
          dark: '#BDECEF' 
        },
      },
    },
    jaJP, // use 'de' locale for UI texts (start, next month, ...)
  );
  
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider 
        dateAdapter={AdapterDayjs} 
        adapterLocale="ja"
        >
          <DatePicker
            components={{
              OpenPickerIcon: CalendarMonthIcon
            }}
            formatDate={(date) => date.format('DD-MM-YYYY')}
            className="my"
            value={value}
            onChange={handleChange}
            inputFormat='YYYY/MM/DD'
            mask='____/__/__'
            renderInput={(params) => <TextField {...params} variant="filled" />}
          />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

