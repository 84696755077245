import { React } from "react";

export default function ListIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.74" viewBox="0 0 20 20.74">
      <g id="リストアイコン" transform="translate(-3334.684 -304.13)">
        <path id="パス_313" data-name="パス 313" d="M10.156,3.906H23.047V5.469H10.156Z" transform="translate(3331.636 302.567)"/>
        <path id="パス_314" data-name="パス 314" d="M1.953,4.688A3.125,3.125,0,1,0,5.078,1.563,3.125,3.125,0,0,0,1.953,4.688ZM5.078,3.125A1.563,1.563,0,1,1,3.515,4.688,1.563,1.563,0,0,1,5.078,3.125Z" transform="translate(3332.731 302.567)"/>
        <path id="パス_315" data-name="パス 315" d="M10.156,11.719H23.047v1.563H10.156Z" transform="translate(3331.636 302)"/>
        <path id="パス_316" data-name="パス 316" d="M5.078,15.625A3.125,3.125,0,1,0,1.953,12.5a3.125,3.125,0,0,0,3.125,3.125Zm0-4.688A1.563,1.563,0,1,1,3.515,12.5a1.563,1.563,0,0,1,1.563-1.563Z" transform="translate(3332.731 302)"/>
        <path id="パス_317" data-name="パス 317" d="M10.156,19.531H23.047v1.563H10.156Z" transform="translate(3331.636 301.432)"/>
        <path id="パス_318" data-name="パス 318" d="M5.078,23.438a3.125,3.125,0,1,0-3.125-3.125,3.125,3.125,0,0,0,3.125,3.125Zm0-4.688a1.563,1.563,0,1,1-1.563,1.563A1.563,1.563,0,0,1,5.078,18.75Z" transform="translate(3332.731 301.432)"/>
      </g>
    </svg>
 );
}
