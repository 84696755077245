import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import { CircularProgress, Backdrop, TextField, Typography, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, Button } from "@mui/material";

import './Company.scss';
import { MyButton } from "./MyButton";
import { MySearch } from "./MySearch";
import { MySelectCount } from "./MySelectCount";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CompanyListItem from "./CompanyListItem";
import { MySelectSortCompany } from "./MySelectSortCompany";

export default function CompanyList() {
  //let {page} = useParams() || 1;

  const { cparam, setCParam, setMenu, getCompanyList, getMedias, getCompanies, companyCategories } = useMyData();
  const navigate = useNavigate();

  const [list, setList] = useState({ list: [] });
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  

  const [page, setPage] = useState( cparam.page ||  1 );

  const [wordProps,, setWord] = useInput(cparam.word || ""); // 検索ワード
  const [companyCategory,setCompanyCategory] = useState([]); // 事業分類一覧
  const [companies,setCompanies] = useState([]); // 事業分類用に会社一覧取得

  const [wordHistory, setWordHistory] = useState(cparam.wordHistory || []); // 検索ワード履歴
  
  const [selectCount,setSelectCount] = useState(cparam.count || 50);
  const [selectSort,setSelectSort] = useState(cparam.sort || 'yomi');




  useEffect(()=>{
    if(cparam.categories && cparam.categories.length > 0 ){
      setCompanyCategory(cparam.categories);
    } else {
      getCompanies(setCompanies,0);
    }
  },[]);
  useEffect(()=>{
    const cat = companyCategories(companies);
    console.log(cat);
    console.log(cparam.cats);

    if(cat.length > 0 && cparam.cats && cparam.cats.length > 0) {
      const cat2 = cat.map((data, i) => {
        return {
          ...data,
          active: cparam.cats.includes(data.category),
        }
      });
      setCompanyCategory(cat2);
    } else {
      setCompanyCategory(cat);
    }

  },[companies]);

  

  useEffect(() => {
    setMenu('companylist');
  }, []);

  useEffect(() => {
    searchHandlerWithPage(page);
  }, []);

  useEffect(() => {
    searchHandlerWithPage(1);
  }, [ selectCount, selectSort]);

  const changePage = (e) => {
    searchHandlerWithPage(e.currentTarget.dataset.page);
  }

  const jumpPage = (e) => {
    navigate('/company/detail/' + e.currentTarget.dataset.id);
  }


  const setSearchWord = (e) => {
    setWord(e.currentTarget.dataset.word);
  }

  const updateHistory = () => {
    // 重複排除
    const set = new Set([
      wordProps.value,
      ...wordHistory.filter((item) => {
        if(
          item == wordProps.value
        ) {
          return false;
        }
        return item;
      })
    ]);

    setWordHistory([...set].slice(0, 5));
  }
  useEffect(() => {
    searchHandlerWithPage(1);
  }, [wordHistory,companyCategory]);

  const searchHandler = () => {
    updateHistory();
  }
  const searchHandlerWithPage = (p) => {
    setLoading(true);
    setList({
      ...list,
      current_page: p,
    })
    const searchParam = {
      word : wordProps.value, // 検索ワード
      wordHistory : wordHistory, // 検索ワード履歴
      categories : companyCategory, // 事業分類
      count : selectCount,
      sort : selectSort,
    };
    getCompanyList({
      page: p ,
      ...searchParam
    }, setList, setLoading);
  }
  const resetHandler = () => {
    setWord("");

    const cat = companyCategory.map((data, i) => {
      return {
        ...data,
        active: false,
      }
    });
    setCompanyCategory(cat);

  }
  
  const makePages = () => {
    const pagecount = 9;
    let localPages = [];
    let startP = list.current_page - 1 - Math.floor(pagecount / 2);
    if (startP < 0) {
      startP = 0;
    }
    let endP = startP + pagecount - 1;
    if(endP >= Number(list.last_page)) {
      endP = list.last_page - 1;
      startP = endP - pagecount + 1;
      if (startP < 0) {
        startP = 0;
      }
    }
    for (let p=startP; p <= endP; p++) {
      localPages.push(p);
    }
    setPages(localPages);
    setPrev(list.current_page > 1 ? true : false);
    setNext(list.current_page < list.last_page ? true : false);
  }
  useEffect(()=>{
    makePages();
  },[list]);

  const buttonClick = (e) => {

    const cat = companyCategory.map((data, i) => {
      if(data.category == e.target.dataset.id) {
        return {
          ...data,
          active: !data.active
        }
      } else {
        return data;
      }
    });

    setCompanyCategory(cat);
    setCParam({
      ...cparam,
      cats: cat.filter(item => item.active).map(item => { return item.category }),
    });
  }

  
  //list.last_page 
  //value + 1 == list.current_page

  return (
    <div className="company">
      <div className="tableBase nopadding">
        <dl className="searchConditions">
          <dt>キーワード</dt>
          <dd>
            <MySearch {...wordProps} />
            <MyButton onClick={searchHandler}>検索</MyButton>
            <br />
            {wordHistory.map((data,i) => <span key={i} className="history" data-word={data} onClick={setSearchWord}>{data}</span>)}
          </dd>
          <dt>
            事業分類
          </dt>
          <dd>
                {companyCategory.map((data,i) => 
                  <Button
                    key={i}
                    variant="contained"
                    className={"comapnySelect " + (data.active ? 'active' : '')}
                    onClick={buttonClick}
                    data-id={data.category}

                    >
                      {data.category_name}
                    </Button>
                )}
          </dd>
        </dl>
      </div>

      <div className="tableBase">
        <div className="pager">
          {list.last_page > 0 && list.list.length > 0 &&
            <>
              <ul>
                {prev && <li className="withIcon"><span onClick={changePage} data-page={list.current_page - 1}><KeyboardDoubleArrowLeftIcon /></span></li>}
                {pages.map((value) => <li key={list.current_page + '-' + value}>
                  {value + 1 == list.current_page
                    ? <span className="current" onClick={changePage} data-page={value + 1}>{value + 1}</span>
                    : <span to="#" onClick={changePage} data-page={value + 1}>{value + 1}</span>}
                </li>)}
                {next && <li className="withIcon"><span onClick={changePage} data-page={list.current_page + 1}><KeyboardDoubleArrowRightIcon /></span></li>}
              </ul>
            </>
          }
          
          <div className="selects">
            <MySelectCount value={selectCount} onChange={(e)=>{setSelectCount(e.target.value)}} />
            <MySelectSortCompany value={selectSort} onChange={(e)=>{setSelectSort(e.target.value)}} />
          </div>

        </div>
        {loading ? 
        <div className="loading">
          <CircularProgress />
        </div>
        :
        <>
          {list.list.length > 0 ?
           <CompanyListItem list={list} />
          :
          <div>
            条件にマッチする「グループ企業」は、ありません。<br /><br />
          </div>
          }
        </>
        }
      </div>


    </div>
  );
}
