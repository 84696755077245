import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link, useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./MediaSelectDialogEditable";
import CompanySelectDialog from "./CompanySelectDialogBig";
import LoadingButton from '@mui/lab/LoadingButton';

import './Dekigoto.scss';

export default function DekigotoAdd() {
  const { data,setMenu,getMedias,getCompanies,dekigotoAdd } = useMyData();
  const navigate = useNavigate();

  const [yearProps,,setYear] = useInput("0000","number"); // 年
  const [monthProps,,setMonth] = useInput("00","number"); // 月
  const [dayProps,,setDay] = useInput("00","number"); // 日

  const [titleProps] = useInput(""); // タイトル
  const [descriptionProps] = useInput(""); // 説明文章①
  const [commanderProps] = useInput(""); // 作業指示者
  const [nameProps] = useInput(data.name||""); // 氏名
  const [postProps] = useInput(data.post||""); // 所属・部署名
  const [commentProps] = useInput(""); // コメント

  const [media,setMedia] = useState([]); // 掲載場所
  const [company,setCompany] = useState([]); // 関連会社

  const [loading,setLoading] = useState(false);

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [ret, setRet] = useState(true); // 登録時の戻り値

  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if(e){
      setMedia(e);
    }
  }

  const openCompanyDialog = (e) => {
    setCompanyDialogOpen(true);
  }
  const closeCompanyDialog = (e) => {
    setCompanyDialogOpen(false);
    if(e){
      setCompany(e);
    }
  }

  useEffect(()=>{
    getMedias(setMedia);
  },[]);

  useEffect(()=>{
    getCompanies(setCompany);
  },[]);

  useEffect(()=>{
    setMenu('dekigoto');
  },[]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(yearProps.value==="") err=true;
    if(yearProps.value < 0 ) setYear(0);
    if(yearProps.value > 2500 ) setYear(2500);
    if(monthProps.value==="") err=true;
    if(monthProps.value < 0 ) setMonth(0);
    if(monthProps.value > 12 ) setMonth(12);
    if(dayProps.value==="") err=true;
    if(dayProps.value < 0 ) setDay(0);
    if(dayProps.value > 31 ) setDay(31);
    if(titleProps.value==="") err=true;
    if(postProps.value==="") err=true;
    if(nameProps.value==="") err=true;

    setHasError(err);
  });

  const addData = () => {
    setLoading(true);
    dekigotoAdd({
      year: yearProps.value,
      month: monthProps.value,
      day: dayProps.value,

      title: titleProps.value,
      description: descriptionProps.value,
      commander:commanderProps.value,
      post:postProps.value,
      name:nameProps.value,
      comment:commentProps.value,
      media,
      company,
    },setRet);
  }

  useEffect(()=>{
    if(ret.status==='OK'){
      navigate('/dekigoto/data/' + ret.v4);
    }
  },[ret]);

  return (
    <div className="dekigoto">
      <div className="date disabled">0000/00/00</div>
      <div className="title disabled">編集中のタイトルがこちらに表示されます</div>
      <div className="tabs">
        <div className="tab active">基本情報入力</div>
        <div className="tab">資料データ入力</div>
        <div className="tab preview ">プレビュー</div>
      </div>
      <div className="mainArea">
        <p>できごとに登録したい情報を入力してください。</p>
        
        <dl>
          <dt className="required">年月日</dt>
          <dd>
            <input type="number" min="0" max="2500" {...yearProps} /> 年　
            <input type="number" min="0" max="12" {...monthProps} /> 月　
            <input type="number" min="0" max="31" {...dayProps} /> 日　
          </dd>

          <dt className="required">タイトル</dt>
          <dd>
            <input type="text" {...titleProps} />
          </dd>

          <dt>説明文章①</dt>
          <dd>
            <TextareaAutosize  {...descriptionProps} />
          </dd>

          <dt>掲載場所</dt>
          <dd>
            <button onClick={openMediaDialog}>選択</button>
            <div className="list">
              {media.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
            </div>
          </dd>

          <dt>関連会社</dt>
          <dd>
            <button onClick={openCompanyDialog}>選択</button>
            <div className="list">
            {company.map((data,i) => <div key={i}>{data.checked ? data.name : ""}</div> )}
            </div>
          </dd>

          <dt>作業指示者</dt>
          <dd>
            <input type="text" {...commanderProps} />
          </dd>

          <dt className="required">最終更新者</dt>
          <dd className="editor">
            <div>
              所属・部署名<br />
              <input type="text" {...postProps} />
            </div>
            <div>
              氏名<br />
              <input type="text" {...nameProps} />
            </div>
          </dd>

          <dt>最終更新日</dt>
          <dd>
            ----/--/--
          </dd>

          <dt>コメント</dt>
          <dd>
            <TextareaAutosize  {...commentProps} />
          </dd>

        </dl>

        <div className="buttons">
          <LoadingButton loading={loading} disabled={ hasError } onClick={addData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
            登録
          </LoadingButton>
        </div>

      </div>

      <MediaSelectDialog open={mediaDialogOpen} onClose={closeMediaDialog} values={media}  />
      <CompanySelectDialog open={companyDialogOpen} onClose={closeCompanyDialog} values={company}  />
    </div>
 );
}
