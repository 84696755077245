import { React } from "react";

export default function ListTileIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="タイルアイコン" transform="translate(-3334.684 -339.5)">
        <g id="長方形_179" data-name="長方形 179" transform="translate(3334.684 339.5)" fill="none" stroke="#000" strokeWidth="1.5">
          <rect width="20" height="20" stroke="none"/>
          <rect x="0.75" y="0.75" width="18.5" height="18.5" fill="none"/>
        </g>
        <line id="線_9" data-name="線 9" x2="19" transform="translate(3335.184 350)" fill="none" stroke="#000" strokeWidth="1.5"/>
        <line id="線_10" data-name="線 10" y2="19" transform="translate(3344.684 340)" fill="none" stroke="#000" strokeWidth="1.5"/>
      </g>
    </svg>
 );
}
