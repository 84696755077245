import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextareaAutosize } from "@mui/material";
import MediaSelectDialog from "./PubMediaSelectDialogEditable";
import LoadingButton from '@mui/lab/LoadingButton';

import './Publication.scss';

export default function PublicationAdd() {
  const { data,setMenu,getPubMedias,publicationAdd } = useMyData();
  const navigate = useNavigate();

  const [yearProps,,setYear] = useInput("0000","number"); // 年
  const [monthProps,,setMonth] = useInput("00","number"); // 月
  const [dayProps,,setDay] = useInput("00","number"); // 日

  const [mediaProps] = useInput(""); // 媒体
  const [titleProps] = useInput(""); // タイトル
  const [nameProps] = useInput(data.name||""); // 氏名
  const [postProps] = useInput(data.post||""); // 所属・部署名

  const [media,setMedia] = useState([]); // 掲載場所

  const [loading,setLoading] = useState(false);

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [ret, setRet] = useState(true); // 登録時の戻り値

  const openMediaDialog = (e) => {
    setMediaDialogOpen(true);
  }
  const closeMediaDialog = (e) => {
    setMediaDialogOpen(false);
    if(e){
      setMedia(e);
    }
  }


  useEffect(()=>{
    getPubMedias(setMedia);
  },[]);

  useEffect(()=>{
    setMenu('publication');
  },[]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(yearProps.value==="") err=true;
    if(yearProps.value < 0 ) setYear(0);
    if(yearProps.value > 2500 ) setYear(2500);
    if(monthProps.value==="") err=true;
    if(monthProps.value < 0 ) setMonth(0);
    if(monthProps.value > 12 ) setMonth(12);
    if(dayProps.value==="") err=true;
    if(dayProps.value < 0 ) setDay(0);
    if(dayProps.value > 31 ) setDay(31);
    if(mediaProps.value==="") err=true;
    if(titleProps.value==="") err=true;
    if(postProps.value==="") err=true;
    if(nameProps.value==="") err=true;
    setHasError(err);
  });

  const addData = () => {
    setLoading(true);
    publicationAdd({
      year: yearProps.value,
      month: monthProps.value,
      day: dayProps.value,

      media: mediaProps.value,
      title: titleProps.value,
      post:postProps.value,
      name:nameProps.value,
    },setRet);
  }

  useEffect(()=>{
    if(ret.status==='OK'){
      navigate('/publication/data/' + ret.v4);
    }
  },[ret]);

  return (
    <div className="publication">
      <div className="date disabled">0000/00/00</div>
      <div className="title disabled">編集中のタイトルがこちらに表示されます</div>
      <div className="tabs">
        <div className="tab active">基本情報入力</div>
        <div className="tab">資料データ入力</div>
      </div>
      <div className="mainArea">
        <p>媒体一覧に登録したい情報を入力してください。</p>
        
        <dl>
          <dt className="required">年月日</dt>
          <dd>
            <input type="number" min="0" max="2500" {...yearProps} /> 年　
            <input type="number" min="0" max="12" {...monthProps} /> 月　
            <input type="number" min="0" max="31" {...dayProps} /> 日　
          </dd>

          <dt className="required">
            媒体選択
            <button className="editTarget" onClick={openMediaDialog}>項目編集</button>
          </dt>
          <dd>
            <FormControl>
              <RadioGroup
                row
                {...mediaProps}
              >
                {media.map((data,i) => 
                  <FormControlLabel key={data.id} value={data.id} control={<Radio
                    sx={{
                      color: "#4D4F5C",
                      '&.Mui-checked': {
                        color: "#26C1C9",
                      },
                    }}/>} label={data.name} />
                )}
              </RadioGroup>
            </FormControl>
          </dd>

          <dt className="required">媒体タイトル</dt>
          <dd>
            <input type="text" {...titleProps} />
          </dd>

          <dt className="required">最終更新者</dt>
          <dd className="editor">
            <div>
              所属・部署名<br />
              <input type="text" {...postProps} />
            </div>
            <div>
              氏名<br />
              <input type="text" {...nameProps} />
            </div>
          </dd>

          <dt>最終更新日</dt>
          <dd>
            ----/--/--
          </dd>

        </dl>

        <div className="buttons">
          <LoadingButton loading={loading} disabled={ hasError } onClick={addData} loadingIndicator={	<CircularProgress color="inherit" size={32} />}>
            登録
          </LoadingButton>
        </div>

      </div>

      <MediaSelectDialog open={mediaDialogOpen} onClose={closeMediaDialog} values={media}  />
    </div>
 );
}
