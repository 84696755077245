import { React, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import './My.scss';

export function MyButtonClose(props) {

  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event);
  }

  
  return (
      <Button  {...props} 
        variant="contained" 
        className={"myclose " + (props.className ? props.className : '')} 
        onClick={props.onClick}
      ><CloseIcon fontSize="large" /></Button>
  );
}

