import { React, useState } from "react";
import { Button } from "@mui/material";

import './My.scss';

export function MyButton(props) {

  return (
      <Button  {...props} variant="contained" className={"my " + (props.className ? props.className : '')} onClick={props.onClick}> {props.children}</Button>
  );
}

