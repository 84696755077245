import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

import './Dekigoto.scss';
import DekigotoEditMain from "./DekigotoEditMain";
import DekigotoEditData from "./DekigotoEditData";
import DekigotoEditPreview from "./DekigotoEditPreview";
import CompanyEditMain from "./CompanyEditMain";
import CompanyEditData from "./CompanyEditData";
import CompanyEditMain2 from "./CompanyEditMain2";
import CompanyEditHistory from "./CompanyEditHistory";
import CompanyEditPreview from "./CompanyEditPreview";

export default function CompanyEdit() {
  let {id} = useParams();

  const { setMenu,getCompanyMedias,getCompanies,getCompany } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル

  const [media,setMedia] = useState([]); // 掲載場所
  const [companies,setCompanies] = useState({}); // 企業情報
  const [company,setCompany] = useState({}); // 企業情報



  const [tab,setTab] = useState(0); // TAB

  useEffect(()=>{
    getCompanyMedias(setMedia,id);
  },[id]);

  useEffect(()=>{
    getCompanies(setCompanies,id);
  },[id]);

  useEffect(()=>{
    getCompany(setCompany,id);
  },[id]);

  useEffect(()=>{
    if(typeof company.foundation_date !== 'undefined' ){
      const dates = company.foundation_date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(company.name);
    }
  
  },[company]);


  useEffect(()=>{
    setMenu('company');
  },[]);


  return (
    <div className="company">
      <div className="date">{yearProps.value}/{monthProps.value}/{dayProps.value}</div>
      <div className="title">{titleProps.value}</div>
      <div className="tabs">
        <div className={"tab" + (tab==0 ? ' active' : '')} onClick={()=>{setTab(0)}} data-tab="0">基本情報入力①</div>
        <div className={"tab" + (tab==1 ? ' active' : '')} onClick={()=>{setTab(1)}} data-tab="1">基本情報入力②</div>
        <div className={"tab" + (tab==2 ? ' active' : '')} onClick={()=>{setTab(2)}} data-tab="2">沿革</div>
        <div className={"tab" + (tab==3 ? ' active' : '')} onClick={()=>{setTab(3)}} data-tab="3">資料データ入力</div>
        <div className={"tab preview" + (tab==4 ? ' active' : '')} onClick={()=>{setTab(4)}} data-tab="4">プレビュー</div>
      </div>
      {tab==0 && 
        <CompanyEditMain id={id} company={company} companies={companies} media={media} />
      }
      {tab==1 && 
        <CompanyEditMain2 id={id} company={company} />
      }
      {tab==2 && 
        <CompanyEditHistory id={id} company={company} />
      }
      {tab==3 && 
        <CompanyEditData id={id} company={company} media={media} />
      }
      {tab==4 && 
        <CompanyEditPreview id={id} company={company} companies={companies} media={media} />
      }
    </div>
 );
}
