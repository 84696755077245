import { Fragment, React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import FolderIcon from './Icons/FolderIcon';
import FileIcon from './Icons/FileIcon';
import ImageIcon from './Icons/ImageIcon';


import './FilesListDialog.scss';


export default function FilesListDialogList({
  data = [],
  onClick = f => f, 
  updatePath = f => f,
  selectFile = f => f,
}) {

  const clickHandler = () => {
    if(data.isdir){
      updatePath(data.filename);
    } else {
      selectFile(data.id);
    }
    onClick();
  }

  const icon = () => {
    if (data.isdir) {
      return <FolderIcon />;
    }
    if ( data.mime_type.match("image") ) {
      return <ImageIcon />;
    }
    return <FileIcon />;
  }

  return (
    <div key={data.id} className={"itemsItem" + (data.selected ? " selected" : "")} onClick={clickHandler}>
      <div className="itemsItemName">{icon()}　{data.filename}</div>
      <div className="itemsItemDate">{data.cdatetime}</div>
      <div className="itemsItemSize">{data.isdir ? '' : Math.ceil(data.filesize / 1024) + 'KB'}</div>
    </div>
  );

}
