import { React, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInput } from "./hooks";
import './MediaEditDialog.scss';


export default function MediaEditDialog(props) {

  const [open, setOpen] = useState(false);
  const [titleProps,, setTitle] = useInput(""); // タイトル

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setTitle(props.value);
  }, [props.value]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleYes = () => {
    setOpen(false);
    props.onYes(titleProps.value);
    setTitle("");
  };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mediaEditDialog"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {props.msg}
          </DialogContentText>
          <input type="text" {...titleProps} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleClose}
            color="monobuttoncolor"
          >キャンセル</Button>
          <Button
            variant="contained" 
            onClick={handleYes}
            color="accent"
            >
            {props.mainbutton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
 );

}
