import { React, useEffect } from "react";
import { useMyData } from './MyProvider';

export default function Top() {
  const { setMenu } = useMyData();

  useEffect(()=>{
    setMenu('');
  },[]);

  return (
    <></>
 );
}
