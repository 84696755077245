import { React } from "react";

export default function InvisibleIcon() {

  return (
  <svg id="eye-slash" xmlns="http://www.w3.org/2000/svg" width="22.719" height="18.044" viewBox="0 0 22.719 18.044">
    <path id="パス_10034" data-name="パス 10034" d="M23.294,18.032a19.405,19.405,0,0,0,3.75-4.6s-4.26-7.81-11.359-7.81a9.979,9.979,0,0,0-3.961.835l1.093,1.095a8.44,8.44,0,0,1,2.868-.51,10.589,10.589,0,0,1,7.338,3.489,18.649,18.649,0,0,1,2.357,2.9c-.083.124-.173.26-.277.409a18.732,18.732,0,0,1-2.08,2.492c-.234.234-.478.466-.734.69Z" transform="translate(-4.325 -4.412)" fill="#2e3d49"/>
    <path id="パス_10035" data-name="パス 10035" d="M19.773,16.762a4.97,4.97,0,0,0-6.353-6.353l1.169,1.169a3.55,3.55,0,0,1,4.017,4.017l1.167,1.167Zm-4.179,1.845,1.167,1.167a4.97,4.97,0,0,1-6.353-6.353l1.169,1.169a3.55,3.55,0,0,0,4.017,4.017Z" transform="translate(-3.733 -6.07)" fill="#2e3d49"/>
    <path id="パス_10036" data-name="パス 10036" d="M4.757,11.72q-.383.341-.735.692a18.65,18.65,0,0,0-2.358,2.9l.277.409a18.732,18.732,0,0,0,2.08,2.492A10.591,10.591,0,0,0,11.359,21.7a8.411,8.411,0,0,0,2.868-.511l1.093,1.1a9.981,9.981,0,0,1-3.961.835C4.26,23.122,0,15.312,0,15.312a19.339,19.339,0,0,1,3.75-4.6l1.005,1.007Z" transform="translate(0 -6.29)" fill="#2e3d49"/>
    <path id="パス_10037" data-name="パス 10037" d="M20.743,21.747,3.7,4.708,4.709,3.7,21.748,20.742Z" transform="translate(-1.366 -3.703)" fill="#2e3d49" fillRule="evenodd"/>
  </svg>
 );
}
