import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

import './Publication.scss';
import PublicationEditMain from "./PublicationEditMain";
import PublicationEditData from "./PublicationEditData";

export default function PublicationEdit() {
  let {id} = useParams();

  const { setMenu,getPubMedias,getPublication } = useMyData();

  const [yearProps,, setYear] = useInput("0000","number"); // 年
  const [monthProps,, setMonth] = useInput("00","number"); // 月
  const [dayProps,, setDay] = useInput("00","number"); // 日

  const [titleProps,, setTitle] = useInput(""); // タイトル

  const [media,setMedia] = useState([]); // 掲載場所

  const [publication, setPublication] = useState({}); // 出来事データ


  const [tab,setTab] = useState(0); // TAB

  useEffect(()=>{
    getPubMedias(setMedia);
  },[]);

  useEffect(()=>{
    getPublication(setPublication,id);
  },[id]);

  useEffect(()=>{
    if(typeof publication.date !== 'undefined' ){
      const dates = publication.date.split('-');
      setYear(dates[0]);
      setMonth(dates[1]);
      setDay(dates[2]);

      setTitle(publication.title);
    }
  
  },[publication]);


  useEffect(()=>{
    setMenu('publication');
  },[]);


  return (
    <div className="publication">
      <div className="date">{yearProps.value}/{monthProps.value}/{dayProps.value}</div>
      <div className="title">{titleProps.value}</div>
      <div className="tabs">
        <div className={"tab" + (tab==0 ? ' active' : '')} onClick={()=>{setTab(0)}} data-tab="0">基本情報入力</div>
        <div className={"tab" + (tab==1 ? ' active' : '')} onClick={()=>{setTab(1)}} data-tab="1">資料データ入力</div>
      </div>
      {tab==0 && 
        <PublicationEditMain id={id} publication={publication} media={media} />
      }
      {tab==1 && 
        <PublicationEditData id={id} publication={publication} media={media} />
      }
    </div>
 );
}
