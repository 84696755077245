import { React } from "react";

export default function FileDownloadIcon({color="#2f3d4a"}) {

  

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.236" height="17" viewBox="0 0 19.236 17">
      <g id="グループ_10869" data-name="グループ 10869" transform="translate(-9826.882 -3355.5)">
        <line id="線_21" data-name="線 21" x2="4.683" y2="4.683" transform="translate(9831.816 3362.817)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
        <line id="線_22" data-name="線 22" x2="4.683" y2="4.683" transform="translate(9841.184 3362.817) rotate(90)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
        <line id="線_23" data-name="線 23" y1="10" transform="translate(9836.5 3356.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
        <line id="線_24" data-name="線 24" x2="17.236" transform="translate(9827.882 3371.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
        <line id="線_25" data-name="線 25" y2="5" transform="translate(9827.882 3366.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
        <line id="線_26" data-name="線 26" y2="5" transform="translate(9845.118 3366.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
 );
}
  