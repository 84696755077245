import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FilesListDialog from "./FilesListDialog";
import FileDownloadIcon from "./Icons/FileDownloadIcon"
import ListAddIcon from "./Icons/ListAddIcon"
import { useMyData } from './MyProvider';

import './Publication.scss';

export default function PublicationListMediaPub({data, handleChange = f => f, updateFiles = f => f}) {

  const { fileDownloadZip, loadPublicationFiles, downloadlist, setDownloadlist } = useMyData();

  const navigate = useNavigate();

  const [checked, setChecked] = useState(0); // 選択／解除

  const [filesDialogOpen, setFilesDialogOpen] = useState(false);
  const [ready, setReady] = useState(true);

  const [mode, setMode] = useState("");


  useEffect(() => {
    setChecked(
      data.checked
    )
    if( mode == 'adddownload' ) {
      adddownloadMain();
    }
    if( mode == 'download' ) {
      downloadMain();
    }
  }, [data]);

  const setFiles = (files) => {
    updateFiles(data.v4, files);
  }
  
  const handleChangeLocal = (event) => {
    //setChecked(event.target.checked ? 1 : 0);
    handleChange(data.v4, event.target.checked ? 1 : 0);
  }

  const jumpPage = (e) => {
    if(e.target == e.currentTarget) {
      navigate('/publication/data/' + e.currentTarget.dataset.id);
    }
  }
  const openFilesDialog = (e) => {
    loadPublicationFiles(data.v4,openFilesDialogSetFiles);
  }
  const openFilesDialogSetFiles = (files) => {
    updateFiles(data.v4, files);
    setFilesDialogOpen(true);
  }
  const closeFilesDialog = (e) => {
    setFilesDialogOpen(false);
  }

  const updateFile = (e) => {
    loadPublicationFiles(data.v4,setFiles);
  }

  const adddownload = (e) => {
    loadPublicationFiles(data.v4,adddownloadSetFiles);
  }
  const adddownloadSetFiles = (files) => {
    updateFiles(data.v4, files);
    setMode('adddownload');
  }
  const adddownloadMain = (e) => {
    setMode('');
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...data.files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return true;
        //return downloadlist.indexOf(item.id) < 0 && item.id == selectfile;
      })
    ]);
    setDownloadlist([...set]);
  }
  
  const download = (e) => {
    loadPublicationFiles(data.v4,downloadSetFiles);
  }
  const downloadSetFiles = (files) => {
    updateFiles(data.v4, files);
    setMode('download');
  }
  const downloadMain = (e) => {
    setMode('');
    const keep = data.files.map( (d,i) => { return d.id } );
    fileDownloadZip(keep, setReady);
  }

  return (
    <>
      <div onClick={jumpPage} data-id={data.v4} className="listMediaPub">
        <FormControlLabel control={
          <Checkbox 
            checked={checked==1}
            onChange={handleChangeLocal}
            sx={{
              color: "#4D4F5C",
              '&.Mui-checked': {
                color: "#26C1C9",
              },
              '&.MuiCheckbox-indeterminate': {
                color: "#26C1C9",
              },
            }} />
          } label={data.title} />

        <div className="buttonArea">
          {data.filecount > 0 &&
            <>
            <div className="dataArea">
              <Button className="data" onClick={openFilesDialog}>付属データ <span className="num">{data.filecount}</span></Button>
            </div>
            <Button className="addList" onClick={adddownload}><ListAddIcon /> ダウンロードリストに追加</Button>
            <Button className="download" onClick={download} disabled={!ready}><FileDownloadIcon color="#fff" /> {ready ? "一括ダウンロード" : "準備中"}</Button>
            </>
          }
        </div>

      </div>
      <FilesListDialog open={filesDialogOpen} onClose={closeFilesDialog} updateFile={updateFile} files={data.files} title={data.title} id={data.v4} />
    </>

  );
}
