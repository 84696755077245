import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import { Badge, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import './Company.scss';
import FileDataView from "./FileDataView";

export default function CompanyListItemPeace({ddata }) {
  const { data, loadCompanyFiles, downloadlist, setDownloadlist } = useMyData();

  const navigate = useNavigate();

  const [files, setFiles] = useState([]); // Files
  const [logofile, setLogoFile] = useState([]); // LogoFile

  const jumpPage = (e) => {
    navigate('/company/detail/' + e.currentTarget.dataset.id);
    //navigate('/company/data/' + e.currentTarget.dataset.id);
  }

  let ffiles = [];
  useEffect(() => {
    loadCompanyFiles(ddata.v4, setInitFiles);
  }, []);

  const setInitFiles = (fs) => {
    ffiles = fs;
    setFiles(ffiles);
  }

  useEffect(() => {
    let logofile = [];
    files.forEach(ele => {
      if(ele.logo==1) {
        logofile.push(ele);
      }
    });
    setLogoFile(logofile);
  },[files]);

  const adddownload = (e) => {
    // 重複排除
    const set = new Set([
      ...downloadlist,
      ...files.filter((item) => {
        if(
          downloadlist.filter((ditem) => {
            return ditem.id == item.id
          }).length > 0 
        ) {
          return false;
        }
        return downloadlist.indexOf(item.id) < 0 && ddata.fileid==item.id;
      })
    ]);
    setDownloadlist([...set]);
  }

  const openFilesDialog = () => {

  }

  return (
      <>
        {ddata && 
        <>
          <div className="image" onClick={jumpPage} data-id={ddata.v4}>
            <div className="imageArea">
              {logofile[0] && 
                <FileDataView key={logofile[0].id} data={logofile[0]} mode="image" show={true} />
              }
            </div>
            <div className="category">{ddata.category}</div>
            <div className="title">{ddata.name}</div>
            <div className="title_en">{ddata.name_en}</div>
            {files.length > 0 && <Button className="data" onClick={openFilesDialog}>付属データ <span className="num">{files.length}</span></Button>}
            <dl>
              <dt>更新日</dt>
              <dd>{ddata.mdate}</dd>
              <dt>指示者</dt>
              <dd>{ddata.commander}</dd>
              <dt>更新者</dt>
              <dd>{ddata.user_post} {ddata.user_name}</dd>
            </dl>
          </div>
        </>
        }
      </>

  );
}
